import * as React from "react";
import { useTheme } from "@mui/material";
import { Main } from "layouts/main";
import styles from "./assets/scss/clients.module.scss";
import clients from "./assets/imgs/g2758.svg";
import { Button } from "components/mui/button";
import { EmptyContent } from "components/empty-content";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomTextField from "components/mui/text-field/text-field";
import emailicon from "./assets/imgs/email.svg";
import person from "./assets/imgs/proicons_person.svg";
import address from "./assets/imgs/address.svg";
import phone from "./assets/imgs/phone.svg";
import axios from "axios";
import { uiActions } from "store/ui/ui-slice";
import { useDispatch, useSelector } from "react-redux";
import { ApiResponse, Client } from "./types/clients";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import edit from "./assets/imgs/edit.svg";
import archive from "./assets/imgs/mage_archive.svg";
import { ReactComponent as MoreVertIcon } from "./assets/imgs/more.svg";
import invoiceIcon from "./assets/imgs/hugeicons_invoice-02.svg";
import projectsIcon from "./assets/imgs/project-management_1087927 1.svg";
import { toPascal } from "utils/text";

const Clients = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [editOpen, setEditOpen] = React.useState(false);
  const [errors, setErrors] = React.useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });
  const [clientsList, setClientsList] = React.useState<Client[]>([]);
  const dispatch = useDispatch();
  const apiURL = process.env.REACT_APP_API_URL || "";
  const app = useSelector((state: any) => state.app?.allData || {});

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedClientId, setSelectedClientId] = React.useState<string | null>(
    null
  );
  const [editClientData, setEditClientData] = React.useState<Client | null>(
    null
  );
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [formValues, setFormValues] = React.useState({
    name: "",
    email: "",
    phone: "",
    address: "",
  });

  React.useEffect(() => {
    const isAllFieldsFilled = Object.values(formValues).every(
      (val) => val.trim() !== ""
    );
    const isAllFieldsValid = Object.values(errors).every(
      (error) => error === ""
    );
    setIsFormValid(isAllFieldsFilled && isAllFieldsValid);
  }, [formValues, errors]);

  const handleMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    clientId: string
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedClientId(clientId);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedClientId(null);
  };

  const handleDelete = async () => {
    if (selectedClientId) {
      try {
        const response = await axios.post(
          `${apiURL}/update-client-status/${selectedClientId}/`
        );

        setClientsList(
          clientsList.filter((client) => client.id !== selectedClientId)
        );

        if (response.status === 200) {
          dispatch(
            uiActions.updateNotification({
              message: response.data.message || "Client archived successfully.",
              level: "success",
            })
          );
        } else {
          dispatch(
            uiActions.updateNotification({
              message: response.data.message || "Failed to archive client.",
              level: "error",
            })
          );
        }
      } catch (error: any) {
        dispatch(
          uiActions.updateNotification({
            message: error.response?.data?.message || "Error archiving client",
            level: "error",
          })
        );
      }

      handleMenuClose();
    }
  };
  const handleEdit = (clientId: string) => {
    const clientToEdit = clientsList.find((client) => client.id === clientId);
    if (clientToEdit) {
      setEditClientData(clientToEdit);
      setEditOpen(true);
    }
    handleMenuClose();
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setEditClientData(null);
  };

  const handleEditSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());

    if (editClientData) {
      try {
        const updatedClientData = {
          ...editClientData,
          name: formJson.name,
          email: formJson.email,
          phone: formJson.phone,
          address: formJson.address,
        };

        const response = await axios.put(
          `${apiURL}/api/clients/${editClientData.id}/`,
          updatedClientData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        setClientsList(
          clientsList.map((client) =>
            client.id === editClientData.id ? response.data.data : client
          )
        );

        if (response.data.isOk) {
          dispatch(
            uiActions.updateNotification({
              message: response.data.message,
              level: "success",
            })
          );
        } else {
          dispatch(
            uiActions.updateNotification({
              message: response.data.message || "error",
              level: "error",
            })
          );
        }

        handleEditClose();
      } catch (error) {
        dispatch(
          uiActions.updateNotification({
            message: "Error updating client",
            level: "error",
          })
        );
      }
    }
  };
  React.useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(`${apiURL}/clients/${app.entityId}/`);
        setClientsList(response.data.data);
      } catch (error) {
        console.error("Error fetching clients:", error);
        dispatch(
          uiActions.updateNotification({
            message: "Error fetching clients",
            level: "error",
          })
        );
      }
    };

    fetchClients();
  }, [apiURL, dispatch, app.entityId]);
  const handleButtonClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({ name: "", email: "", phone: "", address: "" });
    setFormValues({ name: "", email: "", phone: "", address: "" });
    setIsFormValid(false);
  };

  const validateField = (name: string, value: string) => {
    let error = "";
    if (!value.trim()) {
      error = "This field is required.";
    } else if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        error = "Invalid email format.";
      }
    } else if (name === "phone") {
      const phoneRegex = /^\+\d{11,15}$/;
      if (!phoneRegex.test(value)) {
        error = "Phone must start with + and have 11 to 15 digits.";
      }
    } else if (name === "address") {
      const addressParts = value.split(",").map((part: string) => part.trim());
      if (addressParts.length !== 5) {
        error =
          "Address must have 5 parts separated by commas (Street, City, State, Country, Zip).";
      } else {
        const [street, city, state, country, zip] = addressParts;

        if (street.length < 5) error = "Street must be at least 5 characters.";
        else if (city.length < 3) error = "City must be at least 3 characters.";
        else if (state.length < 2)
          error = "State must be at least 2 characters.";
        else if (country.length < 2)
          error = "Country must be at least 2 characters.";
        else if (!/^\d{5}$/.test(zip))
          error = "Zip code must be exactly 5 digits.";
      }
    }
    return error;
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    const error = validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const newErrors = {
      name: validateField("name", formValues.name),
      email: validateField("email", formValues.email),
      phone: validateField("phone", formValues.phone),
      address: validateField("address", formValues.address),
    };

    if (Object.values(newErrors).some((error) => error !== "")) {
      setErrors(newErrors);
      return;
    }

    const clientData = {
      name: formValues.name,
      email: formValues.email,
      phone: formValues.phone,
      address: formValues.address,
      entity_id: app.entityId,
    };

    try {
      const response = await axios.post<Client>(
        `${apiURL}/api/clients/`,
        clientData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      dispatch(
        uiActions.updateNotification({
          message: response.data.name
            ? "Client created successfully"
            : "Client created but no response data",
          level: "success",
        })
      );

      const fetchResponse = await axios.get<ApiResponse>(
        `${apiURL}/clients/${app.entityId}/`
      );

      setClientsList(fetchResponse.data.data);

      handleClose();
    } catch (error) {
      dispatch(
        uiActions.updateNotification({
          message: "Error creating client",
          level: "error",
        })
      );
    }
  };

  return (
    <Main
      action={{ title: "Add Client", clickHandler: handleButtonClick }}
      header={{ icon: clients, title: "Clients ( premium)" }}
    >
      {clientsList.length === 0 ? (
        <EmptyContent
          text="There are no Clients yet, you can"
          linkText="add now."
          onChange={handleButtonClick}
        />
      ) : (
        <div className={styles.clientsList}>
          <h2>All Clients</h2>
          <hr
            style={{
              border: ` 1px solid ${theme.custom.neutralColors.color5}`,
            }}
          />
          <div className={styles.listContainer}>
            {clientsList.map((client) => (
              <div key={client.id} className={styles.clientCard}>
                <div className={styles.clientInfo}>
                  <div
                    className={styles.circle}
                    style={{
                      backgroundColor: theme.palette.primary.main,
                      color: theme.custom.neutralColors.color11,
                    }}
                  >
                    {client.name.charAt(0).toUpperCase() +
                      client.name.charAt(1).toUpperCase()}
                  </div>
                  <div className={styles.details}>
                    <h2
                      style={{
                        color: theme.custom.neutralColors.color9,
                        margin: 5,
                      }}
                    >
                      {toPascal(client.name)}
                    </h2>
                    <div className={styles.contactInfo}>
                      <div>
                        <img src={phone} alt="Phone" />
                        <span
                          style={{
                            color: theme.custom.neutralColors.color7,
                            fontSize: "16px",
                          }}
                        >
                          {client.phone}
                        </span>
                      </div>
                      <div>
                        <img src={emailicon} alt="Email" />
                        <span
                          style={{
                            color: theme.custom.neutralColors.color7,
                            fontSize: "16px",
                          }}
                        >
                          {client.email}
                        </span>
                      </div>
                      <div>
                        <img src={address} alt="Address" />
                        <span
                          style={{
                            color: theme.custom.neutralColors.color7,
                            fontSize: "16px",
                          }}
                        >
                          {client.address.split(",").slice(-3).join(",").trim()}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.invoiceProjects}>
                  <div className={styles.invoice}>
                    <img src={invoiceIcon} alt="Invoice" />
                    <span
                      style={{
                        color: theme.custom.neutralColors.color7,
                      }}
                    >
                      Invoice: 6
                    </span>
                  </div>
                  <div className={styles.projects}>
                    <img src={projectsIcon} alt="Projects" />
                    <span
                      style={{
                        color: theme.custom.neutralColors.color7,
                      }}
                    >
                      Projects: 6
                    </span>
                  </div>
                </div>

                <div className={styles.clientMenu}>
                  <button
                    className={styles.menuButton}
                    onClick={(e) => handleMenuClick(e, client.id)}
                  >
                    <MoreVertIcon />
                  </button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedClientId === client.id}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleEdit(client.id)}>
                      <img
                        src={edit}
                        alt="Edit"
                        style={{ marginRight: "4hbfpx" }}
                      />{" "}
                      Modification
                    </MenuItem>
                    <MenuItem onClick={handleDelete}>
                      <img src={archive} alt="Archive" />
                      Archive
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Add Client Dialog */}
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: handleSubmit,
          sx: {
            width: "50%",
            minWidth: "400px",
          },
        }}
      >
        <DialogTitle
          style={{
            fontSize: "32px",
            fontWeight: 600,
            color: theme.palette.primary.main,
          }}
        >
          Add Client
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <div className={styles.ClientElement}>
            <label
              style={{
                color: theme.custom.font.black,
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Client Name{" "}
              <span style={{ color: theme.palette.error.main }}>*</span>{" "}
            </label>
            <CustomTextField
              placeholder="Client Name"
              width="100%"
              iconSrc={person}
              name="name"
              variant="outlined"
              error={!!errors.name}
              helperText={errors.name}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.ClientElement}>
            <label
              style={{
                color: theme.custom.font.black,
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Client email{" "}
              <span style={{ color: theme.palette.error.main }}>*</span>{" "}
            </label>
            <CustomTextField
              placeholder="default@example.com"
              width="100%"
              iconSrc={emailicon}
              name="email"
              variant="outlined"
              error={!!errors.email}
              helperText={errors.email}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.ClientElement}>
            <label
              style={{
                color: theme.custom.font.black,
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Client mobile number{" "}
              <span style={{ color: theme.palette.error.main }}>*</span>{" "}
            </label>
            <CustomTextField
              placeholder="+12345678910"
              width="100%"
              iconSrc={phone}
              name="phone"
              variant="outlined"
              error={!!errors.phone}
              helperText={errors.phone}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.ClientElement}>
            <label
              style={{
                color: theme.custom.font.black,
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Client Address{" "}
              <span style={{ color: theme.palette.error.main }}>*</span>{" "}
            </label>
            <CustomTextField
              placeholder="Street, City, State, Country, Zip"
              width="100%"
              iconSrc={address}
              name="address"
              variant="outlined"
              error={!!errors.address}
              helperText={errors.address}
              onChange={handleInputChange}
            />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 24px",
          }}
        >
          <Button
            label="Add"
            sx={{
              color: theme.custom.neutralColors.color11,
              backgroundColor: theme.palette.primary.main,
              width: "45%",
            }}
            type="submit"
            disabled={!isFormValid}
          />
          <Button
            label="Cancel"
            sx={{
              color: theme.palette.primary.main,
              backgroundColor: theme.custom.neutralColors.color11,
              border: ` 1px solid ${theme.palette.primary.main}`,
              width: "45%",
            }}
            onClick={handleClose}
          />
        </DialogActions>
      </Dialog>

      {/* Edit Client Dialog */}
      <Dialog
        open={editOpen}
        onClose={handleEditClose}
        PaperProps={{
          component: "form",
          onSubmit: handleEditSubmit,
          sx: {
            width: "50%",
            minWidth: "400px",
          },
        }}
      >
        <DialogTitle
          style={{
            fontSize: "32px",
            fontWeight: 600,
            color: theme.palette.primary.main,
          }}
        >
          Edit Client
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <div className={styles.ClientElement}>
            <label
              style={{
                color: theme.custom.font.black,
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Client Name{" "}
              <span style={{ color: theme.palette.error.main }}>*</span>{" "}
            </label>
            <CustomTextField
              placeholder="Client Name"
              width="100%"
              iconSrc={person}
              name="name"
              variant="outlined"
              defaultValue={editClientData?.name}
              error={!!errors.name}
              helperText={errors.name}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.ClientElement}>
            <label
              style={{
                color: theme.custom.font.black,
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Client email{" "}
              <span style={{ color: theme.palette.error.main }}>*</span>{" "}
            </label>
            <CustomTextField
              placeholder="default@example.com"
              width="100%"
              iconSrc={emailicon}
              name="email"
              variant="outlined"
              defaultValue={editClientData?.email}
              error={!!errors.email}
              helperText={errors.email}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.ClientElement}>
            <label
              style={{
                color: theme.custom.font.black,
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Client mobile number{" "}
              <span style={{ color: theme.palette.error.main }}>*</span>{" "}
            </label>
            <CustomTextField
              placeholder="+12345678910"
              width="100%"
              iconSrc={phone}
              name="phone"
              variant="outlined"
              defaultValue={editClientData?.phone}
              error={!!errors.phone}
              helperText={errors.phone}
              onChange={handleInputChange}
            />
          </div>
          <div className={styles.ClientElement}>
            <label
              style={{
                color: theme.custom.font.black,
                fontWeight: 400,
                fontSize: "18px",
              }}
            >
              Client Address{" "}
              <span style={{ color: theme.palette.error.main }}>*</span>{" "}
            </label>
            <CustomTextField
              placeholder="Street, City, State, Country, Zip"
              width="100%"
              iconSrc={address}
              name="address"
              variant="outlined"
              defaultValue={editClientData?.address}
              error={!!errors.address}
              helperText={errors.address}
              onChange={handleInputChange}
            />
          </div>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 24px",
          }}
        >
          <Button
            label="Save"
            sx={{
              color: theme.custom.neutralColors.color11,
              backgroundColor: theme.palette.primary.main,
              width: "45%",
            }}
            type="submit"
          />
          <Button
            label="Cancel"
            sx={{
              color: theme.palette.primary.main,
              backgroundColor: theme.custom.neutralColors.color11,
              border: ` 1px solid ${theme.palette.primary.main}`,
              width: "45%",
            }}
            onClick={handleEditClose}
          />
        </DialogActions>
      </Dialog>
    </Main>
  );
};

export default Clients;
