import axios from "axios";

const apiURL = process.env.REACT_APP_API_URL || "";

export const createProject = async (payload: {
  name: string;
  description: string;
  client_name: string;
  client_id: string;
  entity_id: string;
}) => {
  try {
    const response = await axios.post(`${apiURL}/create-project/`, payload);
    return response.data;
  } catch (error) {
    throw new Error("Failed to create project");
  }
};
