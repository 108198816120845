import React, { useEffect, useState } from "react";
import { Typography, useTheme, Tabs, Tab, Box } from "@mui/material";
import { Main } from "layouts/main";
import setting from "./assets/imgs/setting.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SettingsIcon } from "./assets/imgs/proicons_person.svg";
import { ReactComponent as NotificationsIcon } from "./assets/imgs/hugeicons_notification-02.svg";
import { ReactComponent as ResetIcon } from "./assets/imgs/reset_17237842 1 (1).svg";
import { ReactComponent as SettingsnTimer } from "./assets/imgs/Group.svg";
import { ReactComponent as Info } from "./assets/imgs/share_5072764 1.svg";
import Styles from "./assets/scss/setting.module.scss";
import { uiActions } from "store/ui/ui-slice";
import Profile from "./Profile";
import Reset from "./Reset";

const Setting: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL || "";
  const app = useSelector((state: any) => state.app?.allData || {});

  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const tabContent = [
    <Box
      key="tab1"
      sx={{ display: "flex", flexDirection: "column", gap: theme.spacing(4) }}
    >
      {" "}
      <Profile />
    </Box>,

    <Typography key="tab2">
      <Reset />
    </Typography>,
    <Typography key="tab3">Content of Tab 3</Typography>,
    <Typography key="tab4">Content of Tab 4</Typography>,
    <Typography key="tab5">Content of Tab 5</Typography>,
  ];

  return (
    <Main header={{ icon: setting, title: "Setting" }}>
      <Box sx={{ width: "100%" }}>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          aria-label="setting tabs"
          variant="fullWidth"
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: theme.spacing(2),
          }}
        >
          <Tab
            icon={<SettingsIcon />}
            iconPosition="start"
            label="Profile Setting"
          />
          <Tab icon={<ResetIcon />} iconPosition="start" label="Reset" />
          <Tab
            icon={<NotificationsIcon />}
            iconPosition="start"
            label="Notifications"
          />
          <Tab
            icon={<SettingsnTimer />}
            iconPosition="start"
            label="Timer setting"
          />
          <Tab
            icon={<Info />}
            iconPosition="start"
            label="Entity Information"
          />
        </Tabs>
        <Box sx={{ padding: theme.spacing(3) }}>{tabContent[selectedTab]}</Box>
      </Box>
    </Main>
  );
};

export default Setting;
