import React, { useState } from "react";
import styles from "./assets/scss/forget-pass.module.scss";
import { Button } from "components/mui/button";
import note from "./assets/imgs/Vector.svg";
import { useNavigate } from "react-router-dom";
import CustomTextField from "components/mui/text-field/text-field";
import EmailIcon from "./assets/imgs/mage_email-notification.svg";
import axios from "axios";
import { uiActions } from "store/ui/ui-slice";
import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material";
import { Boarding } from "layouts/boarding";

const ForgetPass = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    email: "",
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const apiURL = process.env.REACT_APP_API_URL || "";

  const validateInputs = (email: string) => {
    const errors: { email: string } = {
      email: "",
    };

    if (!email) {
      errors.email = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = "Enter a valid email.";
    }

    setValidationErrors(errors);
    return errors.email === "";
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);

    // Validate email on every change
    validateInputs(value);
  };

  const handleLogin = async () => {
    // Validate inputs before submitting the form
    if (!validateInputs(inputValue)) {
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post(
        `${apiURL}/change-password/`,
        { email: inputValue },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.isOk) {
        dispatch(
          uiActions.updateNotification({
            message:
              response.data.message || "Verification code sent successfully!",
            level: "success",
          })
        );
        navigate("../");
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: "error",
          })
        );
      }
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.message ||
        "Failed to send verification code. Please try again.";
      dispatch(
        uiActions.updateNotification({
          message: errorMessage,
          level: "error",
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Boarding
      paragraphContent="Don’t Have an account?"
      cardLabel="Forget password"
    >
      <a href="../">
        <img
          src={note}
          alt="Note Icon"
          style={{
            width: "16px",
            height: "16px",
            position: "relative",
            right: "50%",
            top: "-55px",
          }}
        />
      </a>

      <p
        style={{
          fontSize: "16px",
          fontWeight: 600,
          margin: 0,
          color: theme.custom.font.light,
          textAlign: "left",
          marginBottom: "30px",
        }}
      >
        You can reset your password if you forget it in one of two ways: using
        your phone number or via email.{" "}
      </p>
      <div className={styles.inputContainer}>
        <div className={styles.signupElement}>
          <label
            style={{
              color: theme.custom.font.black,
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            E-mail
          </label>{" "}
          <CustomTextField
            placeholder="default@example.com"
            width="100%"
            value={inputValue}
            onChange={handleChange}
            error={!!validationErrors.email}
            helperText={validationErrors.email}
            variant="outlined"
            iconSrc={EmailIcon}
          />
        </div>
      </div>

      <Button
        label={isSubmitting ? "Sending..." : "Send verification code"}
        sx={{
          color: theme.custom.neutralColors.color11,
          backgroundColor: theme.palette.primary.light,
          width: "100%",
        }}
        onClick={handleLogin}
        disabled={isSubmitting || !!validationErrors.email}
      />
      <hr
        style={{
          border: "1px solid #847E8E",
          width: "100%",
          margin: "20px 0",
        }}
      ></hr>
      <p style={{ fontSize: "15px" }}>
        By proceeding, you agree to the{" "}
        <span style={{ color: theme.palette.primary.light }}>
          Terms of Service
        </span>{" "}
        and{" "}
        <span style={{ color: theme.palette.primary.light }}>
          Privacy Policy
        </span>
      </p>
    </Boarding>
  );
};

export default ForgetPass;
