import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import { CustomTextFieldProps } from "./types/TextField";

const CustomTextField: React.FC<CustomTextFieldProps> = ({
  error = false,
  helperText,
  width = "100%",
  iconSrc,
  className,
  ...textFieldProps
}) => {
  return (
    <div className={className} style={{ width }}>
      <TextField
        error={error}
        style={{
          width,
          marginBottom: "10px",
          fontFamily: "'Cairo', sans-serif",
        }}
        InputProps={{
          startAdornment: iconSrc && (
            <InputAdornment position="start">
              <img src={iconSrc} style={{ width: "20px", height: "20px" }} />
            </InputAdornment>
          ),
          style: {
            fontFamily: "'Cairo', sans-serif",
            fontSize: "1rem",
          },
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            fontFamily: "'Cairo', sans-serif",
          },
          "& .MuiOutlinedInput-input": {
            fontFamily: "'Cairo', sans-serif",
            textTransform: "none",
            fontSize: "1rem",
          },
          "& .MuiOutlinedInput-input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px transparent inset",
            WebkitTextFillColor: "inherit",
            caretColor: "inherit",
          },
        }}
        {...textFieldProps}
      />
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </div>
  );
};

export default CustomTextField;
