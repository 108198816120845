import React, { useState, useRef } from "react";
import styles from "./assets/scss/verify.module.scss";
import { Button } from "components/mui/button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { uiActions } from "store/ui/ui-slice";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material";
import { Boarding } from "layouts/boarding";

const VerifyCodeInput = () => {
  const [verificationCode, setVerificationCode] = useState<string[]>([
    "",
    "",
    "",
    "",
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();

  const app = useSelector((state: any) => state.app?.allData || {});

  const handleVerificationCodeChange = (index: number, value: string) => {
    if (value.length > 1) return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value && index < 3) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleVerificationKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && !verificationCode[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handleVerifyCode = async () => {
    const fullCode = verificationCode.join("");
    if (fullCode.length < 4) {
      dispatch(
        uiActions.updateNotification({
          message: "Please enter the complete verification code.",
          level: "error",
        })
      );
      return;
    }

    setIsLoading(true);

    try {
      const response = await axios.post(`${apiURL}/verify/`, {
        id: app.memberId,
        verifyCode: fullCode,
      });
      if (response.data.isOk) {
        navigate("../home");

        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "Verification successful!",
            level: "success",
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Verification error:", error);
      dispatch(
        uiActions.updateNotification({
          message: "Verification failed, please try again.",
          level: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };
  const apiURL = process.env.REACT_APP_API_URL || "";

  const handleResendVerificationCode = async () => {
    setIsResending(true);

    try {
      const response = await axios.post(`${apiURL}/verify/resend/`, {
        id: app.memberId,
      });

      dispatch(
        uiActions.updateNotification({
          message:
            response.data.message || "Verification code resent successfully.",
          level: "success",
        })
      );
    } catch (error) {
      console.error("Resend verification error:", error);
      dispatch(
        uiActions.updateNotification({
          message: "Failed to resend verification code, please try again.",
          level: "error",
        })
      );
    } finally {
      setIsResending(false);
    }
  };

  const handleLoginClick = () => {
    navigate("../");
  };

  return (
    <Boarding
      buttonLabel="Login"
      paragraphContent="Already have an account?"
      cardLabel="Verify your account"
      onButtonClick={handleLoginClick}
    >
      <img
        src={require("./assets/imgs/material-symbols-light_mark-email-read-outline.png")}
        alt="Email Icon"
        className={styles.emailIcon}
      />
      <div className={styles.verifyText}>
        <div
          style={{
            color: theme.custom.font.black,
            fontSize: "24px",
            fontWeight: 600,
          }}
        >
          We just emailed you.
        </div>
        <div
          style={{
            color: theme.custom.font.light,
            fontSize: "20px",
            fontWeight: 600,
          }}
        >
          Please enter the code we emailed you.
        </div>
        <div
          style={{
            color: theme.custom.font.dark,
            fontSize: "16px",
            fontWeight: 600,
            marginTop: "10px",
          }}
        >
          {app.email || "No Email"}
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div style={{ display: "flex", gap: "10px", justifyContent: "center" }}>
          {verificationCode.map((char, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={char}
              onChange={(e) =>
                handleVerificationCodeChange(index, e.target.value)
              }
              onKeyDown={(e) => handleVerificationKeyDown(index, e)}
              ref={(el) => (inputRefs.current[index] = el)}
              style={{
                width: "50px",
                height: "50px",
                textAlign: "center",
                fontSize: "20px",
                padding: "20px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginBottom: "20px",
              }}
            />
          ))}
        </div>
        <Button
          label={isLoading ? "Verifying..." : "Verify"}
          sx={{
            width: "100%",
            color: theme.custom.neutralColors.color11,
            backgroundColor: theme.palette.primary.light,
          }}
          onClick={handleVerifyCode}
          disabled={isLoading}
        />
        <p
          style={{
            textAlign: "center",
            marginTop: "10px",
            fontWeight: 600,
            fontSize: "20px",
          }}
        >
          Code not received?{" "}
          <a
            href=""
            style={{ color: theme.palette.primary.light }}
            onClick={(e) => {
              e.preventDefault();
              handleResendVerificationCode();
            }}
          >
            Resend
          </a>
        </p>
      </div>
    </Boarding>
  );
};

export default VerifyCodeInput;
