import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import styles from "./assets/scss/custom-data-box.module.scss";
import { CustomDataBoxProps } from "./types/custom-data-box";

const CustomDataBox: React.FC<CustomDataBoxProps> = ({ data, config }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: 800,
          boxShadow: 3,
          borderRadius: 2,
          padding: 3,
          backgroundColor: "background.paper",
        }}
      >
        {Array.isArray(data) && (
          <div>
            {data.map((row, rowIndex) => (
              <div className={styles.container} key={rowIndex}>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {config.map((column, colIndex) => (
                    <Grid item xs={6} key={colIndex}>
                      {column.map((item, itemIndex) => (
                        <Typography variant="body2" key={itemIndex}>
                          <strong
                            style={{ color: theme.custom.neutralColors.color8 }}
                          >
                            {item.label}:
                          </strong>{" "}
                          {row[item.key]}
                        </Typography>
                      ))}
                    </Grid>
                  ))}
                </Grid>
              </div>
            ))}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default CustomDataBox;
