import { colors, createTheme } from "@mui/material";

const neutralColors = {
  color1: "#FAFAFA",
  color2: "#E3E3E3",
  color3: "#CCCBCB",
  color4: "#B5B3B3",
  color5: "#9F9C9C",
  color6: "#898384",
  color7: "#726C6C",
  color8: "#433E3F",
  color9: "#2B2829",
  color10: "#0A0A0B",
  color11: "#FFFFFF",
  color12: "#463D55",
  color13: "#34928633",
  color1Light: "#FAFAFA80",
  color14: "#007bff",
};

const lightColors = {
  custom: {
    background: { main: "#ffff" },
    font: {
      light: "#726C6C",
      dark: "#433E3F",
      black: "#0A0A0B",
    },
    neutralColors: neutralColors,
  },
  palette: {
    primary: { main: "#226159", light: "#349286", dark: "#226159" },
    secondary: { main: "#2D2042", light: "#847E8E", dark: "#2D2042" },
    warning: { main: "#FFC355", light: "#FFE1AA", dark: "#FFA500" },
    success: { main: "#76E476", light: "#BBF2BB", dark: "#32D732" },
    error: { main: "#FF5A55", light: "#FFADAA", dark: "#FF0800" },
    action: {
      disabled: neutralColors.color3,
      active: "",
      hover: "",
      selected: "",
      disabledBackground: neutralColors.color3,
    },
    text: {
      primary: neutralColors.color7,
      secondary: neutralColors.color12,
      disabled: neutralColors.color3,
    },
    background: {
      default: "white",
      paper: "white",
    },
    divider: neutralColors.color7,
  },
};
const darkColors = {
  custom: {
    background: { main: "#ffff" },
    font: {
      light: "#726C6C",
      dark: "#433E3F",
    },
    neutralColors: {
      color1: "#FAFAFA",
      color2: "#E3E3E3",
      color3: "#CCCBCB",
      color4: "#B5B3B3",
      color5: "#9F9C9C",
      color6: "#898384",
      color7: "#726C6C",
      color8: "#433E3F",
      color9: "#2B2829",
      color10: "#0A0A0B",
      color11: "#FFFFFF",
    },
  },
  palette: {
    primary: { main: "#226159", light: "#349286", dark: "#226159" },
    secondary: { main: "#2D2042", light: "#847E8E", dark: "#2D2042" },
    warning: { main: "#FFC355", light: "#FFE1AA", dark: "#FFA500" },
    success: { main: "#76E476", light: "#BBF2BB", dark: "#32D732" },
    error: { main: "#FF5A55", light: "#FFADAA", dark: "#FF0800" },
  },
};

const defaultThemeInfo = {
  typography: {
    // allVariants: {
    //     color: "#d3302f",
    // },
    fontFamily: "Roboto",
  },
  cssVariables: true,
};

export const lightTheme = createTheme({ ...defaultThemeInfo, ...lightColors });
export const darkTheme = createTheme({ ...defaultThemeInfo, ...darkColors });
