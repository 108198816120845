import React, { useState, useEffect } from "react";
import axios from "axios";
import { Typography, useTheme } from "@mui/material";
import { Main } from "layouts/main";
import projects from "./assets/imgs/Vector.svg";
import styles from "./assets/scss/projects.module.scss";
import { EmptyContent } from "components/empty-content";
import CustomTextField from "components/mui/text-field/text-field";
import { Button } from "components/mui/button";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as EditIcon } from "./assets/imgs/iconamoon_edit-light.svg";
import { ReactComponent as Archive } from "./assets/imgs/mage_archive.svg";
import search from "./assets/imgs/search.svg";
import { CustomDialog } from "components/popup";
import { useNavigate } from "react-router-dom";
import EditProjectDialog from "components/editing/editing-project";
import { Project } from "./types/projects";
import { uiActions } from "store/ui/ui-slice";

const Projects: React.FC = () => {
  const theme = useTheme();
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [projectsList, setProjectsList] = useState<Project[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const apiURL = process.env.REACT_APP_API_URL || "";
  const app = useSelector((state: any) => state.app?.allData || {});

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${apiURL}/list-projects/`, {
        params: { entity_id: app.entityId },
      });
      console.log("API Response:", response.data);

      if (response.data && Array.isArray(response.data.data)) {
        setProjectsList(response.data.data);
      } else {
        console.error("Unexpected API response structure:", response.data);
        setProjectsList([]);
      }
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [apiURL, app.entityId]);

  const handleDeleteProject = async (projectId: string) => {
    try {
      const response = await axios.post(
        `${apiURL}/project-ARCHIVE/${projectId}/`
      );
      setProjectsList((prevProjects) =>
        prevProjects.filter((project) => project.id !== projectId)
      );
      if (response.data.isOk) {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: "success",
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "error",
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };

  const handleEditProject = (project: Project) => {
    setSelectedProject(project);
    setOpenEditDialog(true);
  };

  const handleSaveProject = async (updatedProject: Project) => {
    try {
      const response = await axios.put(
        `${apiURL}/api/projects/${updatedProject.id}/`,
        updatedProject
      );
      if (response.data.isOk) {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: "success",
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "error",
            level: "error",
          })
        );
      }
      setProjectsList((prevProjects) =>
        prevProjects.map((project) =>
          project.id === updatedProject.id ? updatedProject : project
        )
      );
      setOpenEditDialog(false);
      setSelectedProject(null); // Reset selected project after saving
    } catch (error) {
      console.error("Error updating project:", error);
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setSelectedProject(null); // Reset selected project when dialog is closed
  };

  const filteredProjects = projectsList.filter((project) =>
    project.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Main
      action={{
        title: "Create Project",
        clickHandler: () => setOpenCreateDialog(true),
      }}
      header={{ icon: projects, title: "projects" }}
    >
      {/* Search Field */}
      <CustomTextField
        placeholder="Search projects..."
        iconSrc={search}
        width="70%"
        value={searchQuery}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchQuery(e.target.value)
        }
        sx={{
          marginBottom: "8px",
          display: "flex",
          "& .MuiOutlinedInput-input:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            "-webkit-text-fill-color": "inherit",
            "caret-color": "inherit",
          },
        }}
      />

      <Typography
        variant="body2"
        sx={{
          color: theme.custom.neutralColors.color8,
          fontWeight: 600,
          fontSize: "20px",
          textAlign: "left",
        }}
      >
        {filteredProjects.length} projects
      </Typography>

      <hr></hr>

      {/* Projects List or Empty Content */}
      {filteredProjects.length > 0 ? (
        <div className={styles.rowsContainer}>
          {filteredProjects.map((project) => (
            <div
              key={project.id}
              className={styles.row}
              style={{
                borderBottom: `1px solid ${theme.custom.neutralColors.color5}`,
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: 500,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() =>
                  navigate("../project-details", {
                    state: { projectId: project.id, projectName: project.name },
                  })
                }
              >
                {project.name}
              </span>
              <div className={styles.actionContainer}>
                <div>
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleEditProject(project)}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
                <div>
                  <IconButton
                    aria-label="Archive"
                    onClick={() => handleDeleteProject(project.id)}
                  >
                    <Archive />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <EmptyContent
          text="There are no projects yet. You can"
          linkText="start now."
          onChange={() => setOpenCreateDialog(true)}
        />
      )}

      <CustomDialog
        open={openCreateDialog}
        onClose={() => setOpenCreateDialog(false)}
        entityId={app.entityId}
        fetchProjects={fetchProjects}
        memberId={app.memberId}
      />

      {selectedProject && (
        <EditProjectDialog
          open={openEditDialog}
          onClose={handleCloseEditDialog}
          project={selectedProject}
          onSave={handleSaveProject}
        />
      )}
    </Main>
  );
};

export default Projects;
