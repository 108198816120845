import { createSlice } from "@reduxjs/toolkit";

interface Entity {
  id: string;
  name: string;
  is_primary: boolean;
  memberId: string;
  isSelected?: boolean;
}
const appSlice = createSlice({
  name: "app",
  initialState: {
    allData: JSON.parse(localStorage.getItem("appData") || "{}") || {
      token: "",
      avatar: "",
      memberId: "",
      entityId: "",
      email: "",
      entity_name: "",
      role: "",
      last_name: "",
      first_name: "",
      account_type: "",
      tier: "",
      status: "",
      isAnotherEntitySelected: false,
      entities_info: {
        entities: [],
        subscription: {
          account_type: "",
          tier: "",
          status: "",
        },
      },
    },
  },
  reducers: {
    setAppData(state, action) {
      const {
        avatar = "",
        token = "",
        memberId = "",
        entityId = "",
        email = "",
        entity_name = "",
        role = "",
        first_name = "",
        last_name = "",
        entities_info = {},
      } = action.payload.data || {};

      const { subscription = {} } = entities_info;
      const { account_type = "", tier = "", status = "" } = subscription;

      const updatedData = {
        avatar,
        token,
        memberId,
        entityId,
        email,
        entity_name,
        role,
        first_name,
        last_name,
        account_type,
        tier,
        status,
        isAnotherEntitySelected: false,
        entities_info: {
          entities: entities_info.entities.map((entity: Entity) => ({
            ...entity,
            isSelected: entity.id === entityId,
          })),
          subscription,
        },
      };

      state.allData = updatedData;
      localStorage.setItem("appData", JSON.stringify(updatedData));
    },
    setAnotherEntitySelected(state, action) {
      state.allData.isAnotherEntitySelected = action.payload;
      localStorage.setItem("appData", JSON.stringify(state.allData));
    },
  },
});

export const { setAppData, setAnotherEntitySelected } = appSlice.actions;
export const appActions = appSlice.actions;

export default appSlice.reducer;
