export interface Project {
  id: string;
  name: string;
  description: string;
  creation_date: string;
  daily_limit: number;
  Milestone_limit: number;
  project_limit: number;
  Max_Offline_Tasks: number;
  deadline: string;
  status: number;
  priority: string;
  client_name: string;
  Status_Str: string;
}
export interface Milestone {
  id: string;
  name: string;
  status: string;
  daily_limit: number;
  Milestone_limit: number;
  max_offline_tasks: number;
  deadline: string;
  order: number;
}

export interface Member {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  password: string;
  verify_code: string;
  attempt_count: number;
  status: number;
  role: number;
  pay_rate: number | null;
  pay_currency: string | null;
  is_temp: boolean;
  avatar: string;
  primary_entity: {
    id: string;
    name: string;
    moto: string | null;
    timer_approaching_mins: number | null;
    timer_exceeded_mins: number | null;
    daily_limit: number | null;
    max_offline_tasks: number | null;
    pay_rate: number | null;
    pay_currency: string | null;
    status: number;
    avatar: string | null;
  };
}

export interface ApiResponse {
  isOk: boolean;
  code: number;
  message: string;
  data: Member[];
}

export const ProjectStatus = {
  Active: 1,
  Inactive: 2,
  Completed: 3,
};

export interface AddMilestone {
  name: string;
  max_offline_tasks: string;
  daily_limit: number;
  Milestone_limit: number;
  deadline: string;
  status: number;
  order: number;
}

export interface Member {
  id: string;
  name: string;
}
