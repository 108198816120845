import { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import { Button } from "components/mui/button";
import CustomTextField from "components/mui/text-field/text-field";
import CustomSelect from "components/mui/menu/menu";
import { createProject } from "./utils/popup";
import { useDispatch } from "react-redux";
import { uiActions } from "store/ui/ui-slice";
import axios from "axios";
import { CustomDialogProps } from "./types/popup";

const CustomDialog = ({
  open,
  onClose,
  entityId,
  memberId,
  fetchProjects,
}: CustomDialogProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [clients, setClients] = useState<{ id: string; name: string }[]>([]);
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/clients/${entityId}/`
        );
        setClients(response.data.data);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };

    fetchClients();
  }, [entityId]);

  useEffect(() => {
    const isDescriptionValid = projectDescription.length >= 10;
    const isNameValid = projectName.trim() !== "";
    const isClientSelected = selectedClient !== "";

    setIsFormValid(isDescriptionValid && isNameValid && isClientSelected);
  }, [projectName, projectDescription, selectedClient]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isFormValid) return;

    try {
      const selectedClientData = clients.find(
        (client) => client.id === selectedClient
      );
      const clientName = selectedClientData?.name || "";
      const clientId = selectedClientData?.id || "";

      const payload = {
        name: projectName,
        description: projectDescription,
        client_name: clientName,
        client_id: clientId,
        entity_id: entityId,
        member_id: memberId,
      };

      await createProject(payload);

      dispatch(
        uiActions.updateNotification({
          message: "Project created successfully",
          level: "success",
        })
      );

      fetchProjects();

      setProjectName("");
      setProjectDescription("");
      setSelectedClient("");
      onClose();
    } catch (error) {
      dispatch(
        uiActions.updateNotification({
          message: "Failed to create project. Please try again.",
          level: "error",
        })
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        component: "form",
        onSubmit: handleSubmit,
        sx: {
          width: "50%",
          minWidth: "400px",
          overflowX: "hidden",
        },
      }}
    >
      <DialogTitle
        style={{
          fontSize: "32px",
          fontWeight: 600,
          color: theme.palette.primary.main,
        }}
      >
        Add Project
      </DialogTitle>
      <hr
        style={{
          width: "100%",
          border: `1px solid ${theme.custom.neutralColors.color5}`,
        }}
      />
      <DialogContent>
        <div style={{ marginBottom: "16px" }}>
          <label
            style={{
              color: theme.custom.font.black,
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            Project Name{" "}
            <span style={{ color: theme.palette.error.main }}>*</span>
          </label>
          <CustomTextField
            placeholder="Project Name"
            width="100%"
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
          />
        </div>

        <div style={{ marginBottom: "16px" }}>
          <label
            style={{
              color: theme.custom.font.black,
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            Client Name{" "}
            <span style={{ color: theme.palette.error.main }}>*</span>
          </label>
          <CustomSelect
            value={
              clients.find((client) => client.id === selectedClient)?.name || ""
            }
            onChange={(e: any) => setSelectedClient(e.target.value)}
            options={
              Array.isArray(clients)
                ? clients.map((client) => ({
                    value: client.id,
                    label: client.name,
                  }))
                : []
            }
            displayEmpty
            open={false}
          />
        </div>

        <div style={{ marginBottom: "16px" }}>
          <label
            style={{
              color: theme.custom.font.black,
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            Project Description{" "}
            <span style={{ color: theme.palette.error.main }}>*</span>
          </label>
          <CustomTextField
            margin="dense"
            label="Project Description"
            multiline
            rows={4}
            fullWidth
            value={projectDescription}
            onChange={(e) => setProjectDescription(e.target.value)}
            error={projectDescription.length < 10 && projectDescription !== ""}
            helperText={
              projectDescription.length < 10 && projectDescription !== ""
                ? "Description must be at least 10 characters."
                : ""
            }
          />
        </div>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 24px",
        }}
      >
        <Button
          label="Create"
          sx={{
            color: theme.custom.neutralColors.color11,
            backgroundColor: theme.palette.primary.main,
            width: "45%",
          }}
          type="submit"
          disabled={!isFormValid}
        />
        <Button
          label="Cancel"
          sx={{
            color: theme.palette.primary.main,
            backgroundColor: theme.custom.neutralColors.color11,
            border: ` 1px solid ${theme.palette.primary.main}`,
            width: "45%",
          }}
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
