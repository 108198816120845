import { useState, useEffect } from "react";
import axios from "axios";
import { Typography, useTheme } from "@mui/material";
import { Main } from "layouts/main";
import projects from "./assets/imgs/Vector.svg";
import styles from "./assets/scss/projects-details.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Milestone, Project } from "./types/projects-details";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as EditIcon } from "./assets/imgs/iconamoon_edit-light.svg";
import { ReactComponent as Archive } from "./assets/imgs/mage_archive.svg";
import { CustomDataBox } from "components/custom-data-box";
import {
  DataRow,
  TypographyConfig,
} from "components/custom-data-box/types/custom-data-box";
import { Button } from "components/mui/button";
import EditMilestoneDialog from "components/editing/editing-mileston";
import { uiActions } from "store/ui/ui-slice";
import { useDispatch } from "react-redux";
import ProjectsMember from "./project-member";
import CreateMilestone from "./create-milestone";

const ProjectsDetails = () => {
  const theme = useTheme();
  const apiURL = process.env.REACT_APP_API_URL || "";
  const location = useLocation();
  const projectId = location.state?.projectId;
  const projectName = location.state?.projectName;
  const navigate = useNavigate();
  const [milestones, setMilestones] = useState<Milestone[]>([]);
  const [projectData, setProjectData] = useState<Project[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedMilestone, setSelectedMilestone] = useState<Milestone | null>(
    null
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchMilestones = async () => {
      try {
        const response = await axios.get(
          `${apiURL}/projects-milestones/${projectId}/`
        );
        console.log("Milestones API Response:", response.data);

        if (response.data && Array.isArray(response.data.data)) {
          setMilestones(response.data.data);
        } else if (Array.isArray(response.data)) {
          setMilestones(response.data);
        } else {
          console.error("Unexpected API response structure:", response.data);
          setMilestones([]);
        }
      } catch (error) {
        console.error("Error fetching milestones:", error);
        setError("Failed to fetch milestones. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchMilestones();
  }, [apiURL, projectId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiURL}/api/projects/${projectId}/`
        );
        const responseData = Array.isArray(response.data)
          ? response.data
          : [response.data];
        setProjectData(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch project details. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiURL, projectId]);

  const handleReportNavigate = (id: string) => {
    navigate("../report", {
      state: { projectId: projectId },
    });
  };

  const handleDeleteMilestone = async (milestoneId: string) => {
    try {
      const response = await axios.post(
        `${apiURL}/milestone-ARCHIVE/${milestoneId}/`
      );
      setMilestones(
        milestones.filter((milestone) => milestone.id !== milestoneId)
      );
      if (response.data.isOk) {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: "success",
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "error",
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error deleting milestone:", error);
      setError("Failed to delete milestone. Please try again later.");
    }
  };

  const handleEditMilestone = (milestone: Milestone) => {
    setSelectedMilestone(milestone);
    setOpenEditDialog(true);
  };

  const handleSaveMilestone = async (updatedMilestone: Milestone) => {
    try {
      const response = await axios.put(
        `${apiURL}/api/milestones/${updatedMilestone.id}/`,
        updatedMilestone
      );
      if (response.data.isOk) {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: "success",
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "error",
            level: "error",
          })
        );
      }
      setMilestones((prevMilestones) =>
        prevMilestones.map((milestone) =>
          milestone.id === updatedMilestone.id ? updatedMilestone : milestone
        )
      );
      setOpenEditDialog(false); // Close the dialog
      setSelectedMilestone(null); // Reset the selected milestone
    } catch (error) {
      console.error("Error updating milestone:", error);
      setError("Failed to update milestone. Please try again later.");
    }
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false); // Close the dialog
    setSelectedMilestone(null); // Reset the selected milestone
  };
  const data: DataRow[] = projectData.map((project) => ({
    description: project.description,
    creation_date: new Date(project.creation_date).toLocaleString(),
    project_limit: project.project_limit,
    Max_Offline_Tasks: project.Max_Offline_Tasks,
    daily_limit: project.daily_limit,
    Milestone_limit: project.Milestone_limit,
    deadline: project.deadline,
    Status_Str: project.Status_Str,
    priority: project.priority,
    client_name: project.client_name,
  }));

  const config: TypographyConfig[][] = [
    [
      { label: "client name", key: "client_name" },
      { label: "description", key: "description" },
      { label: "creation date", key: "creation_date" },
      { label: "Daily Limit", key: "daily_limit" },
      { label: "Milestone Limit", key: "Milestone_limit" },
    ],
    [
      { label: "project limit", key: "project_limit" },
      { label: "Max Offline Tasks", key: "Max_Offline_Tasks" },
      { label: "Deadline", key: "deadline" },
      { label: "project status", key: "Status_Str" },
      { label: "Priority", key: "priority" },
    ],
  ];

  return (
    <Main header={{ icon: projects, title: projectName }}>
      <CustomDataBox data={data} config={config} />
      <div style={{ width: "100%" }}>
        <Typography
          variant="subtitle1"
          gutterBottom
          sx={{
            color: theme.custom.neutralColors.color8,
            fontWeight: 600,
            fontSize: "20px",
            textAlign: "left",
          }}
        >
          {milestones.length} Milestones
        </Typography>
        <div className={styles.rowsContainer}>
          {milestones.map((milestone) => (
            <div
              key={milestone.id}
              className={styles.row}
              style={{
                borderBottom: `1px solid ${theme.custom.neutralColors.color5}`,
              }}
            >
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: 500,
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
                onClick={() =>
                  navigate("../milestons-details", {
                    state: {
                      milestoneId: milestone.id,
                      milestoneName: milestone.name,
                      projectId: projectId,
                    },
                  })
                }
              >
                {milestone.name}
              </span>
              <div className={styles.actionContainer}>
                <div>
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleEditMilestone(milestone)}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
                <div>
                  <IconButton
                    aria-label="archive"
                    onClick={() => handleDeleteMilestone(milestone.id)}
                  >
                    <Archive />
                  </IconButton>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.buttons}>
        {" "}
        <Button
          label="Project Report"
          sx={{
            color: theme.custom.neutralColors.color11,
            backgroundColor: theme.palette.primary.main,
            borderRadius: "10px",
            width: "20%",
            marginTop: "30px",
          }}
          onClick={() => handleReportNavigate(projectId)}
        />
        <ProjectsMember />
        <CreateMilestone />
      </div>
      {selectedMilestone && (
        <EditMilestoneDialog
          open={openEditDialog}
          onClose={handleCloseEditDialog}
          milestone={selectedMilestone}
          onSave={handleSaveMilestone}
        />
      )}
    </Main>
  );
};

export default ProjectsDetails;
