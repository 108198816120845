import { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { Main } from "layouts/main";
import styles from "./assets/scss/report.module.scss";
import { useDispatch, useSelector } from "react-redux";
import report from "./assets/imgs/report.svg";
import { CustomDataBox } from "components/custom-data-box";
import {
  DataRow,
  TypographyConfig,
} from "components/custom-data-box/types/custom-data-box";
import { Project } from "./types/report";
import PrintIcon from "@mui/icons-material/Print";
import { useLocation } from "react-router-dom";
import { uiActions } from "store/ui/ui-slice";
import axios from "axios";

const Report = () => {
  const theme = useTheme();
  const [reportData, setReportData] = useState<Project[]>([]);

  const dispatch = useDispatch();

  const apiURL = process.env.REACT_APP_API_URL || "";
  const app = useSelector((state: any) => state.app?.allData || {});
  const location = useLocation();
  const projectId = location.state?.projectId;

  useEffect(() => {
    if (!projectId) {
      console.error("Project ID is missing");
      return;
    }

    const fetchReportData = async () => {
      try {
        const response = await axios.get(
          `${apiURL}/generate-report/${projectId}/?account_type=${app.account_type}&tier=${app.tier}`
        );

        const data = response.data.data;

        if (response.data.isOk) {
          dispatch(
            uiActions.updateNotification({
              message: response.data.message || "Report created successfully",
              level: "success",
            })
          );
        } else {
          dispatch(
            uiActions.updateNotification({
              message: response.data.message || "error",
              level: "error",
            })
          );
        }

        const formattedData = Array.isArray(data) ? data : [data];
        setReportData(formattedData);
      } catch (error) {
        console.error("Error fetching report data:", error);
        dispatch(
          uiActions.updateNotification({
            message: "Failed to fetch report data",
            level: "error",
          })
        );
      }
    };

    fetchReportData();
  }, [projectId, apiURL, app.account_type, app.tier, dispatch]);

  const data: DataRow[] = Array.isArray(reportData)
    ? reportData.map((report) => ({
        name: report.name || "N/A",
        project: report.project || "N/A",
        total_hrs: report.total_hrs || 0,
        start_time: new Date(report.start_time).toLocaleString() || "N/A",
        total_overtime: report.total_overtime || 0,
        total_manual_hrs: report.total_manual_hrs || 0,
        total_manual_overtime: report.total_manual_overtime || 0,
        total_live_hrs: report.total_live_hrs || 0,
        total_live_overtime: report.total_live_overtime || 0,
        total_monitored_hrs: report.total_monitored_hrs || 0,
        total_monitored_overtime: report.total_monitored_overtime || 0,
        payment_sent: report.payment_sent || 0,
        payment_received: report.payment_received || 0,
      }))
    : [];

  const config: TypographyConfig[][] = [
    [
      { label: "name", key: "name" },
      { label: "project", key: "project" },
      { label: "total hrs", key: "total_hrs" },
      { label: "start time", key: "start_time" },
      { label: "total overtime", key: "total_overtime" },
      { label: "total manual hrs", key: "total_manual_hrs" },
      { label: "total manual overtime", key: "total_manual_overtime" },
    ],
    [
      { label: "total live hrs", key: "total_live_hrs" },
      { label: "total live overtime", key: "total_live_overtime" },
      { label: "total monitored hrs", key: "total_monitored_hrs" },
      { label: "total monitored overtime", key: "total_monitored_overtime" },
      { label: "payment sent", key: "payment_sent" },
      { label: "payment received", key: "payment_received" },
    ],
  ];

  const handlePrint = () => {
    window.print();
  };

  return (
    <Main header={{ icon: report, title: "Report" }}>
      <div style={{ textAlign: "right", marginBottom: "16px" }}>
        <PrintIcon
          onClick={handlePrint}
          style={{ cursor: "pointer", color: theme.palette.primary.main }}
        />
      </div>

      <CustomDataBox data={data} config={config} />
    </Main>
  );
};

export default Report;
