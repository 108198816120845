import { useState, useEffect } from "react";
import axios from "axios";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  IconButton,
  TextField,
} from "@mui/material";
import { Main } from "layouts/main";
import icon from "./assets/imgs/clipboard_17288168 1.svg";
import styles from "./assets/scss/tasks.module.scss";
import CustomTextField from "components/mui/text-field/text-field";
import { ReactComponent as EditIcon } from "./assets/imgs/iconamoon_edit-light.svg";
import { ReactComponent as DeleteIcon } from "./assets/imgs/bin.svg";
import search from "./assets/imgs/search.svg";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "components/mui/button";
import { uiActions } from "store/ui/ui-slice";
import { Client, Task } from "./types/tasks";
import CustomSelect from "components/mui/menu/menu";

const Tasks = () => {
  const dispatch = useDispatch();

  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const apiURL = process.env.REACT_APP_API_URL || "http://127.0.0.1:8000";
  const app = useSelector((state: any) => state.app?.allData || {});
  const [tasksData, setTasksData] = useState<Task[]>([]);
  const [editingRow, setEditingRow] = useState<string | null>(null);
  const [editedTask, setEditedTask] = useState<Task | null>(null);
  const [deletedTaskId, setDeletedTaskId] = useState<string | null>(null);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [projectsList, setProjectsList] = useState<Client[]>([]);
  const [milestonesList, setMilestonesList] = useState<Client[]>([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedMilestone, setSelectedMilestone] = useState("");

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${apiURL}/list-projects/`, {
        params: { entity_id: app.entityId },
      });
      setProjectsList(response.data.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const fetchMilestones = async (projectId: any) => {
    try {
      const memberId = app.memberId;
      const response = await axios.get(
        `${apiURL}/projects-milestones/${projectId}/`
      );
      if (Array.isArray(response.data.data)) {
        setMilestonesList(response.data.data);
      } else {
        console.error("API response is not an array:", response.data);
        setMilestonesList([]);
      }
    } catch (error) {
      console.error("Error fetching milestones:", error);
      setMilestonesList([]);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [apiURL, app.entityId]);

  useEffect(() => {
    if (selectedProject) {
      fetchMilestones(selectedProject);
      setSelectedMilestone("");
    }
  }, [selectedProject]);
  useEffect(() => {
    setTasksData([]);
    setSelectedMilestone("");
  }, [selectedProject]);

  useEffect(() => {
    if (selectedMilestone) {
      fetchTasks(page, selectedMilestone);
    }
  }, [page, deletedTaskId, selectedMilestone]);

  const fetchTasks = async (pageNumber: number, milestoneId: string) => {
    try {
      const response = await axios.get(
        `${apiURL}/tasks-by-milestone-id/${milestoneId}/?page=${pageNumber}`
      );
      if (response.data.isOk) {
        setTasksData((prevTasks) =>
          pageNumber === 1
            ? response.data.data.tasks
            : [...prevTasks, ...response.data.data.tasks]
        );
        setHasMore(response.data.data.more);
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: "success",
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "error",
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const handleNextPage = () => {
    if (hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleEditClick = (task: Task) => {
    setEditingRow(task.id);
    setEditedTask({ ...task });
  };

  const handleInputChange = (field: keyof Task, value: string) => {
    setEditedTask((prev) => (prev ? { ...prev, [field]: value } : null));
  };

  const handleSaveClick = async () => {
    if (!editedTask || editingRow === null) return;
    const id = editedTask.id;

    const payload = {
      task_name: editedTask.task_name,
      duration: editedTask.duration,
    };

    try {
      const response = await axios.put(`${apiURL}/api/tasks/${id}/`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data.isOk) {
        setTasksData((prev) =>
          prev.map((task) =>
            task.id === editedTask.id ? { ...editedTask } : task
          )
        );
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: "success",
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "error",
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error updating task:", error);
    }

    setEditingRow(null);
    setEditedTask(null);
  };

  const handleDelete = async (taskId: any) => {
    try {
      const response = await axios.post(`${apiURL}/update-status/${taskId}/`);
      if (response.data.isOk) {
        setTasksData((prevTasks) =>
          prevTasks.filter((task) => task.id !== taskId)
        );
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: "success",
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "error",
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Failed to delete task:", error);
    }
  };

  const statusStyles: Record<string, { label: string; color: string }> = {
    New: { label: "NEW", color: theme.custom.neutralColors.color14 },
    Inprogress: { label: "Inprogress", color: theme.palette.warning.dark },
    Completed: { label: "COMPLETED", color: theme.palette.primary.light },
    Declined: { label: "DECLINED", color: theme.palette.error.dark },
    Approved: { label: "APPROVED", color: theme.palette.primary.dark },
    Deleted: { label: "DELETED", color: theme.custom.font.light },
  };

  const getStatus = (status: string) => {
    const statusData = statusStyles[status];
    if (statusData) {
      return statusData;
    }
    return { label: "UNKNOWN", color: "#000" };
  };

  const filteredTasks = tasksData.filter((task) =>
    task.task_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Main header={{ icon: icon, title: "Tasks" }}>
      <CustomTextField
        placeholder="Search tasks..."
        iconSrc={search}
        width="70%"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{
          marginBottom: "8px",
          display: "flex",
          "& .MuiOutlinedInput-input:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 100px transparent inset",
            "-webkit-text-fill-color": "inherit",
            "caret-color": "inherit",
          },
        }}
      />
      <div className={styles.container}>
        <div className={styles.ClientElement}>
          <label
            style={{
              color: theme.custom.font.black,
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            Select Project
            <span style={{ color: theme.palette.error.main }}>*</span>{" "}
          </label>
          <CustomSelect
            value={
              projectsList.find((project) => project.id === selectedProject)
                ?.name || ""
            }
            onChange={(e: any) => setSelectedProject(e.target.value)}
            options={projectsList.map((project) => ({
              value: project.id,
              label: project.name,
            }))}
            displayEmpty
            className={styles.menu}
            open={false}
          />
        </div>

        <div className={styles.ClientElement}>
          <label
            style={{
              color: theme.custom.font.black,
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            Select Milestone
            <span style={{ color: theme.palette.error.main }}>*</span>{" "}
          </label>
          <CustomSelect
            value={
              milestonesList.find(
                (milestone) => milestone.id === selectedMilestone
              )?.name || ""
            }
            onChange={(e: any) => setSelectedMilestone(e.target.value)}
            options={
              Array.isArray(milestonesList)
                ? milestonesList.map((milestone) => ({
                    value: milestone.id,
                    label: milestone.name,
                  }))
                : []
            }
            displayEmpty
            className={styles.menu}
            open={false}
          />
        </div>
      </div>
      <TableContainer className={styles.table}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: "center" }}>Task Name</TableCell>
              <TableCell style={{ textAlign: "center" }}>
                Creation Date
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>
                TRACKER Type
              </TableCell>
              <TableCell style={{ textAlign: "center" }}>Duration</TableCell>
              <TableCell style={{ textAlign: "center" }}>Task Status</TableCell>
              <TableCell style={{ textAlign: "center" }}>Edit</TableCell>
              <TableCell style={{ textAlign: "center" }}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredTasks.map((task, index) => (
              <TableRow key={index}>
                <TableCell
                  style={{
                    color: theme.custom.font.black,
                    textAlign: "center",
                  }}
                >
                  {editingRow === task.id ? (
                    <TextField
                      value={editedTask?.task_name || ""}
                      onChange={(e) =>
                        handleInputChange("task_name", e.target.value)
                      }
                      autoFocus
                    />
                  ) : (
                    task.task_name
                  )}
                </TableCell>
                <TableCell
                  style={{
                    color: theme.custom.font.black,
                    textAlign: "center",
                  }}
                >
                  {new Date(task.creation_date + "Z").toLocaleString()}
                </TableCell>
                <TableCell
                  style={{
                    color: theme.custom.font.black,
                    textAlign: "center",
                  }}
                >
                  {task.tracking_type}
                </TableCell>
                <TableCell
                  style={{
                    color: theme.custom.font.black,
                    textAlign: "center",
                  }}
                >
                  {editingRow === task.id ? (
                    <TextField
                      value={editedTask?.duration || ""}
                      onChange={(e) =>
                        handleInputChange("duration", e.target.value)
                      }
                    />
                  ) : (
                    task.duration
                  )}
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "8px",
                      backgroundColor: getStatus(task.status).color,
                      color: theme.custom.font.black,
                      padding: "4px 0",
                      borderRadius: "16px",
                      fontWeight: "bold",
                      fontSize: "14px",
                    }}
                  >
                    <span
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "50%",
                        backgroundColor: theme.custom.neutralColors.color11,
                      }}
                    ></span>
                    {getStatus(task.status).label}
                  </div>
                </TableCell>
                <TableCell
                  style={{
                    color: theme.custom.font.black,
                    textAlign: "center",
                  }}
                >
                  {editingRow === task.id ? (
                    <IconButton
                      onClick={handleSaveClick}
                      style={{ fontSize: "14px" }}
                    >
                      Save
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => handleEditClick(task)}>
                      <EditIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell
                  style={{
                    color: theme.custom.font.black,
                    textAlign: "center",
                  }}
                >
                  <IconButton onClick={() => handleDelete(task.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {hasMore && (
        <div className={styles.loadMore}>
          <Button
            sx={{
              color: theme.custom.neutralColors.color11,
              backgroundColor: theme.palette.primary.light,
              "&:hover": {
                backgroundColor: theme.palette.primary.dark,
                color: theme.custom.neutralColors.color11,
              },
              "&:active": {
                backgroundColor: theme.palette.primary.main,
                color: theme.custom.neutralColors.color11,
              },
              "&:disabled": {
                backgroundColor: theme.palette.action.disabledBackground,
                color: theme.palette.action.disabled,
                cursor: "not-allowed",
              },
            }}
            onClick={handleNextPage}
            disabled={!hasMore}
            label="  Load More"
          />
        </div>
      )}
    </Main>
  );
};

export default Tasks;
