import * as React from "react";
// redux
import { useSelector } from "react-redux";
import { lightTheme, darkTheme } from "theme/assets/colors";

import { ThemeProvider } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      background: {
        main: string;
      };
      font: {
        light: string;
        dark: string;
        black: string;
      };
      neutralColors: {
        color1: string;
        color2: string;
        color3: string;
        color4: string;
        color5: string;
        color6: string;
        color7: string;
        color8: string;
        color9: string;
        color10: string;
        color11: string;
        color12: string;
        color13: string;
        color1Light: string;
        color14: string;
      };
    };
  }
  interface ThemeOptions {
    backgroundColor?: {
      primary?: string;
      secondary?: string;
    };
    status?: {
      danger?: string;
    };
  }
}

type IProps = {
  children?: any;
};

const ThemeComponent = ({ children }: IProps) => {
  const uiState = useSelector((state: any) => state.ui.theme);
  return (
    <ThemeProvider theme={uiState === "light" ? lightTheme : darkTheme}>
      {children}
    </ThemeProvider>
  );
};
export default ThemeComponent;
