import { useState, useEffect } from "react";
import axios from "axios";
import { Typography, useTheme, Box, Grid } from "@mui/material";
import { Main } from "layouts/main";
import projects from "./assets/imgs/Vector.svg";
import styles from "./assets/scss/milestons-details.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Milestone } from "./types/milestons-details";
import { CustomDataBox } from "components/custom-data-box";
import {
  DataRow,
  TypographyConfig,
} from "components/custom-data-box/types/custom-data-box";
import MilestoneMember from "../projects-details/milestone-member";

const MilestonesDetails = () => {
  const theme = useTheme();
  const apiURL = process.env.REACT_APP_API_URL || "";
  const location = useLocation();
  const milestoneId = location.state?.milestoneId;
  const milestoneName = location.state?.milestoneName;
  const navigate = useNavigate();

  const [milestoneData, setMilestoneData] = useState<Milestone[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiURL}/api/milestones/${milestoneId}/`
        );
        const responseData = Array.isArray(response.data.data)
          ? response.data.data
          : [response.data.data];
        setMilestoneData(responseData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch project details. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiURL, milestoneId]);

  const data: DataRow[] = milestoneData.map((project) => ({
    Max_Offline_Tasks: project.Max_Offline_Tasks,
    daily_limit: project.daily_limit,
    Milestone_limit: project.Milestone_limit,
    deadline: project.deadline,
    Status_Str: project.Status_Str,
    order: project.order,
  }));

  const config: TypographyConfig[][] = [
    [
      { label: "Max Offline Tasks", key: "Max_Offline_Tasks" },
      { label: "Daily Limit", key: "daily_limit" },
      { label: "Milestone Limit", key: "Milestone_limit" },
    ],
    [
      { label: "Deadline", key: "deadline" },
      { label: "milestone status", key: "Status_Str" },
      { label: "order", key: "order" },
    ],
  ];

  return (
    <Main header={{ icon: projects, title: milestoneName }}>
      <CustomDataBox data={data} config={config} />
      <MilestoneMember />
    </Main>
  );
};

export default MilestonesDetails;
