import { useState, useEffect } from "react";
import axios from "axios";
import {
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Button } from "components/mui/button";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomSelect from "components/mui/menu/menu";
import { uiActions } from "store/ui/ui-slice";
import { ApiResponse, Member } from "./types/projects-details";

const ProjectsMember = () => {
  const theme = useTheme();
  const apiURL = process.env.REACT_APP_API_URL || "";
  const location = useLocation();
  const projectId = location.state?.projectId;
  const dispatch = useDispatch();

  const [members, setMembers] = useState<Member[]>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedMember, setSelectedMember] = useState<string>("");
  const app = useSelector((state: any) => state.app?.allData || {});

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await axios.get<ApiResponse>(
          `${apiURL}/entitymembers/${app.entityId}/members/`
        );
        if (response.data.isOk) {
          setMembers(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching members:", error);
      }
    };

    fetchMembers();
  }, []);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleMemberSelect = (memberId: string) => {
    setSelectedMember(memberId);
  };

  const handleAssign = async () => {
    if (selectedMember && projectId) {
      const payload = {
        project: projectId,
        member: selectedMember,
      };

      try {
        const response = await axios.post(
          `${apiURL}/api/project-members/`,
          payload
        );
        if (response.data.isOk) {
          dispatch(
            uiActions.updateNotification({
              message: response.data.message,
              level: "success",
            })
          );
        } else {
          dispatch(
            uiActions.updateNotification({
              message: response.data.message,
              level: "error",
            })
          );
        }
      } catch (error) {
        console.error("Error assigning member:", error);
        alert("Error assigning member.");
      }
    }
    setOpenDialog(false);
  };

  const handleCancel = () => {
    setOpenDialog(false);
  };

  return (
    <div>
      <Button
        sx={{
          color: theme.custom.neutralColors.color11,
          backgroundColor: theme.palette.primary.main,
          flex: 4,
          borderRadius: "10px",
          width: "100%",
          marginTop: "30px",
        }}
        onClick={handleOpenDialog}
        label="Add Member"
      />
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        PaperProps={{
          component: "form",
          sx: {
            width: "50%",
            minWidth: "400px",
          },
        }}
      >
        <DialogTitle>Select a Member</DialogTitle>
        <DialogContent>
          <CustomSelect
            value={
              members.find((member) => member.id === selectedMember)
                ?.firstname || ""
            }
            onChange={(e: any) => handleMemberSelect(e.target.value)}
            options={
              Array.isArray(members)
                ? members.map((member) => ({
                    value: member.id,
                    label: `${member.firstname} ${member.lastname}`,
                  }))
                : []
            }
            displayEmpty
            open={false}
          />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 24px",
          }}
        >
          {" "}
          <Button
            sx={{
              color: theme.custom.neutralColors.color11,
              backgroundColor: theme.palette.primary.main,
              width: "45%",
            }}
            onClick={handleAssign}
            label="Assign"
          />
          <Button
            sx={{
              color: theme.palette.primary.main,
              backgroundColor: theme.custom.neutralColors.color11,
              border: ` 1px solid ${theme.palette.primary.main}`,
              width: "45%",
            }}
            onClick={handleCancel}
            label="Cancel"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProjectsMember;
