import { Card, useTheme } from "@mui/material";
import styles from "./assets/scss/container.module.scss";
import { Button } from "components/mui/button";
import { ContainerProps } from "./types/container";

const Container = ({
  children,
  headerIcon,
  headerLabel = "Container Label",
  buttonLabel,
  onButtonClick,
  welcomeText,
}: ContainerProps) => {
  const theme = useTheme();

  return (
    <div className={styles.layoutRightSideContainer}>
      <div
        className={styles.gap}
        style={{
          color: theme.palette.secondary.dark,
          // flex: welcomeText ? 2 : 1,
          minHeight: welcomeText ? "10vh" : "7vh",
        }}
      >
        {welcomeText}
      </div>
      <Card className={styles.card} sx={{}}>
        <div
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.custom.neutralColors.color11,
          }}
          className={styles.cardHeader}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {headerIcon && (
              <img
                src={headerIcon}
                alt="Header Icon"
                style={{ width: "24px", height: "24px", marginRight: "10px" }}
              />
            )}
            <span style={{ fontSize: "16px", fontWeight: 400 }}>
              {headerLabel}
            </span>
          </div>

          {buttonLabel && (
            <Button
              label={buttonLabel}
              sx={{
                color: theme.palette.primary.light,
                backgroundColor: theme.custom.neutralColors.color11,
                width: "auto",
              }}
              onClick={onButtonClick}
            />
          )}
        </div>

        <div
          style={{
            backgroundColor: theme.palette.background.default,
          }}
          className={styles.contentContainer}
        >
          {children}
        </div>
      </Card>
    </div>
  );
};

export default Container;
