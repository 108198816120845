import React, { useEffect, useState } from "react";
import { Button } from "components/mui/button";
import { Main } from "layouts/main";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  Card,
  Grid,
  Typography,
  IconButton,
  Box,
} from "@mui/material";
import Help from "./assets/imgs/ooui_help-rtl.svg";
import Rocket from "./assets/imgs/mynaui_rocket.svg";
import home from "./assets/imgs/Vector.svg";
import { ReactComponent as Time } from "./assets/imgs/Recent icon.svg";
import Project from "./assets/imgs/proj.svg";
import styles from "./assets/scss/home.module.scss";
import { useSelector } from "react-redux";
import { toPascal } from "utils/text";
import { EmptyContent } from "components/empty-content";
import axios from "axios";
import { Client } from "./types/home";
import { useNavigate } from "react-router-dom";
import { CustomDialog } from "components/popup";

const Home = () => {
  const theme = useTheme();
  const app = useSelector((state: any) => state.app?.allData || {});
  const [projectsList, setProjectsList] = useState<Client[]>([]);
  const apiURL = process.env.REACT_APP_API_URL || "";
  const [tasksData, setTasksData] = useState<any[]>([]);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);

  const fetchTasks = async () => {
    try {
      const response = await axios.get(`${apiURL}/last-tasks/`, {
        params: {
          n: 5,
          entity_id: app.entityId,
        },
      });
      if (response.data.isOk) {
        setTasksData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };
  const statusStyles: Record<string, { label: string; color: string }> = {
    1: { label: "NEW", color: theme.custom.neutralColors.color14 },
    2: { label: "Inprogress", color: theme.palette.warning.dark },
    3: { label: "COMPLETED", color: theme.palette.primary.light },
    4: { label: "DECLINED", color: theme.palette.error.dark },
    5: { label: "APPROVED", color: theme.palette.primary.dark },
    6: { label: "ARCHIVE", color: theme.custom.font.light },
  };

  const getStatus = (status: string) => {
    const statusData = statusStyles[status];
    if (statusData) {
      return statusData;
    }
    return { label: "UNKNOWN", color: "#000" };
  };
  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${apiURL}/last-projects/`, {
        params: {
          n: 2,
          entity_id: app.entityId,
        },
      });
      setProjectsList(response.data.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };
  useEffect(() => {
    fetchTasks();
  }, [apiURL, app.entityId]);

  useEffect(() => {
    fetchProjects();
  }, [apiURL, app.entityId]);

  const handleButtonClick = () => {
    setOpenDialog(true);
  };

  const formatDate = (timestamp: string) => {
    const date = new Date(timestamp);
    return date.toISOString().split("T")[0];
  };

  return (
    <Main
      header={{ icon: home, title: "Home" }}
      welcomeText={`Hi, ${toPascal(app.first_name)}`}
    >
      <div className={styles.homeContentContainer}>
        <div className={styles.cardContainer}>
          <Card
            className={styles.card}
            elevation={3}
            sx={{
              border: `1px solid ${theme.custom.neutralColors.color2}`,
              backgroundColor: `${theme.palette.background.default}`,
            }}
          >
            <div className={styles.cardHeader}>
              <Typography variant="h6" className={styles.Text}>
                Recent Projects
              </Typography>
            </div>

            {projectsList.length > 0 ? (
              <div className={styles.projectsContainer}>
                {projectsList.map((project) => (
                  <Grid
                    item
                    xs={12}
                    sm={projectsList.length === 1 ? 12 : 6}
                    key={project.id}
                    className={styles.projectContainer}
                    style={{
                      width: projectsList.length === 1 ? "50%" : "100%",
                      margin: "0 auto",
                    }}
                  >
                    <Card
                      elevation={3}
                      className={styles.projectGrid}
                      style={{
                        backgroundColor: theme.custom.neutralColors.color1Light,
                      }}
                    >
                      {/* Green Rectangle with Icon */}
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                          gap: "15px",
                          flex: 1,
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: theme.palette.primary.light,
                            borderRadius: "4px",
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          <IconButton>
                            <img
                              src={Project}
                              alt="Project Icon"
                              className={styles.icon}
                            />
                          </IconButton>
                        </div>

                        <Typography
                          variant="h6"
                          style={{
                            textAlign: "left",
                            color: theme.custom.neutralColors.color9,
                          }}
                        >
                          {toPascal(project.name)}
                        </Typography>
                      </Box>

                      {/* Project Details */}
                      <Box className={styles.descriptionContainer}>
                        <Typography
                          variant="body2"
                          style={{
                            color: theme.custom.neutralColors.color8,
                            textAlign: "left",
                          }}
                        >
                          {`     ${toPascal(project.description)}`}
                        </Typography>
                      </Box>
                      <Box className={styles.dateContainer}>
                        <Typography
                          variant="caption"
                          style={{
                            color: theme.custom.neutralColors.color8,
                          }}
                        >
                          <Time
                            style={{
                              position: "relative",
                              top: "4px",
                            }}
                          />{" "}
                          {formatDate(project.creation_date)}
                        </Typography>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "calc(100% - 60px)",
                }}
              >
                <EmptyContent
                  text="There are no projects yet. You can"
                  linkText="start now!"
                  onChange={handleButtonClick}
                />
              </div>
            )}
          </Card>

          {/* Right Section */}
          <div className={styles.rightSection}>
            <h3 style={{ textAlign: "left" }}>Learn & get inspired</h3>
            <Grid
              container
              direction="column"
              spacing={1}
              sx={{ paddingLeft: "15px" }}
            >
              <Grid item>
                <Grid container alignItems="left" spacing={1}>
                  <Grid item>
                    <div
                      className={styles.iconContainer}
                      style={{ backgroundColor: theme.palette.primary.main }}
                    >
                      <IconButton color="primary" className={styles.iconButton}>
                        <img
                          src={Rocket}
                          alt="Rocket Icon"
                          className={styles.icon}
                        />
                      </IconButton>
                    </div>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" style={{ textAlign: "left" }}>
                      Getting started
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      Learn how ticktrack.com works
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <div
                      className={styles.iconContainer}
                      style={{
                        backgroundColor: theme.custom.neutralColors.color12,
                      }}
                    >
                      <IconButton color="primary" className={styles.iconButton}>
                        <img
                          src={Help}
                          alt="Help Icon"
                          className={styles.icon}
                        />
                      </IconButton>
                    </div>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1" style={{ textAlign: "left" }}>
                      Help center
                    </Typography>
                    <Typography variant="caption" color="textSecondary">
                      Learn and get support
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>

        {/* Table Section */}
        <div className={styles.tableSection}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h6" className={styles.sectionTitle}>
                Recent Tasks
              </Typography>
            </Grid>
            <Grid item>
              <a
                style={{
                  color: theme.palette.primary.light,
                  width: "100%",
                }}
                href="../tasks/"
              >
                Show all
              </a>
            </Grid>
          </Grid>

          <TableContainer className={styles.table}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>
                    Task Name
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Creation Date
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    TRACKER Type
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Duration
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    Task Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasksData.slice(0, 5).map((task, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        color: theme.custom.font.black,
                        textAlign: "center",
                      }}
                    >
                      {task.task_name}
                    </TableCell>
                    <TableCell
                      style={{
                        color: theme.custom.font.black,
                        textAlign: "center",
                      }}
                    >
                      {" "}
                      {new Date(task.start_time).toLocaleString()}
                    </TableCell>
                    <TableCell
                      style={{
                        color: theme.custom.font.black,
                        textAlign: "center",
                      }}
                    >
                      {task.tracking_type}
                    </TableCell>
                    <TableCell
                      style={{
                        color: theme.custom.font.black,
                        textAlign: "center",
                      }}
                    >
                      {task.duration}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "8px",
                          backgroundColor: getStatus(task.status).color,
                          color: theme.custom.font.black,
                          padding: "4px 0",
                          borderRadius: "16px",
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        <span
                          style={{
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            backgroundColor: theme.custom.neutralColors.color11,
                          }}
                        ></span>
                        {getStatus(task.status).label}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {/* Reusable Dialog Component */}
        <CustomDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          entityId={app.entityId}
          memberId={app.memberId}
          fetchProjects={fetchProjects}
        />
      </div>
    </Main>
  );
};

export default Home;
