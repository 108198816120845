import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  TextField,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import styles from "./assets/scss/header.module.scss";
import { Button } from "components/mui/button";

import searchicon from "./assets/imgs/search.svg";
import logo from "./assets/imgs/Frame 427319045.png";
import clock from "./assets/imgs/fluent_timer-28-regular.svg";
import alarm from "./assets/imgs/alarm.svg";
import setting from "./assets/imgs/uiw_setting-o.svg";
import chat from "./assets/imgs/qlementine-icons_faq-16.svg";
import { useDispatch, useSelector } from "react-redux";
import { setAnotherEntitySelected, setAppData } from "store/slices/app";

interface Entity {
  id: string;
  name: string;
  is_primary: boolean;
  memberId: string;
  isSelected?: boolean;
}

interface EntityMenuProps {
  entities: Entity[];
  onSelect: (id: string) => void;
  isOpen: boolean;
}

const EntityMenu: React.FC<EntityMenuProps> = ({
  entities,
  onSelect,
  isOpen,
}) => {
  const theme = useTheme();

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "absolute",
        top: "60px",
        right: "20px",
        backgroundColor: "#fff",
        border: "1px solid #ccc",
        borderRadius: "4px",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
        color: theme.custom.font.black,
      }}
    >
      {entities.map((entity: Entity) => (
        <div
          key={entity.id}
          style={{
            padding: "10px",
            cursor: "pointer",
            borderBottom: "1px solid #eee",
            backgroundColor: entity.isSelected ? "#e0e0e0" : "#fff",
          }}
          onClick={() => onSelect(entity.id)}
        >
          {entity.name}
        </div>
      ))}
    </div>
  );
};

const Header = () => {
  const dispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const app = useSelector((state: any) => state.app?.allData || {});

  const handleCreate = () => {
    console.log("Create Tracker clicked");
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.value);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleEntitySelect = (entityId: string) => {
    console.log("Selected Entity ID:", entityId);
    setIsMenuOpen(false);

    const selectedEntity = app.entities_info.entities.find(
      (entity: Entity) => entity.id === entityId
    );

    if (!selectedEntity) return;

    const updatedEntities = app.entities_info.entities.map(
      (entity: Entity) => ({
        ...entity,
        isSelected: entity.id === entityId,
      })
    );

    const updatedAppData = {
      ...app,
      entityId: selectedEntity.id,
      entity_name: selectedEntity.name,
      entities_info: {
        ...app.entities_info,
        entities: updatedEntities,
      },
    };

    dispatch(setAnotherEntitySelected(true));
    dispatch(
      setAppData({
        data: updatedAppData,
      })
    );
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: theme.custom.neutralColors.color11 }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0 20px",
        }}
      >
        {/* Logo on the left */}
        <a href="">
          <img
            src={logo}
            alt="Logo"
            style={{
              width: isSmallScreen ? 120 : 150,
              height: 40,
            }}
          />
        </a>

        {/* Centered Button and TextField */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
            margin: isSmallScreen ? "10px 0" : "0",
          }}
        >
          <Button
            label="Create Tracker"
            className={styles.searchButton}
            sx={{
              color: theme.custom.neutralColors.color11,
              backgroundColor: theme.palette.primary.light,
            }}
            onClick={handleCreate}
          />
          <TextField
            placeholder="Search..."
            variant="outlined"
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchicon} alt="Search Icon" />
                </InputAdornment>
              ),
            }}
            sx={{
              width: isSmallScreen ? "80%" : "25vw",
              marginLeft: "10px",
              "& .MuiOutlinedInput-root": {
                fontFamily: "'Cairo', sans-serif",
              },
              "& .MuiOutlinedInput-input": {
                fontFamily: "'Cairo', sans-serif",
                fontSize: "1rem",
                padding: "7px",
              },
              "& .MuiOutlinedInput-input:-webkit-autofill": {
                "-webkit-box-shadow": "0 0 0 100px transparent inset",
                "-webkit-text-fill-color": "inherit",
                "caret-color": "inherit",
              },
            }}
          />
        </div>

        {/* Icons on the right */}
        <div
          className={styles.headerRight}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <span className={styles.icon}>
            <img src={clock} alt="Clock" width="20" height="20" />
          </span>
          <span className={styles.icon}>
            <img src={alarm} alt="Alarm" width="20" height="20" />
          </span>
          <span className={styles.icon}>
            <img src={setting} alt="Settings" width="20" height="20" />
          </span>
          <span className={styles.icon}>
            <img src={chat} alt="Chat" width="20" height="20" />
          </span>
          <span
            style={{
              backgroundColor: theme.palette.primary.main,
              color: theme.custom.neutralColors.color11,
              cursor: "pointer",
            }}
            className={styles.circle}
            onClick={toggleMenu}
          >
            {app.entity_name ? app.entity_name.charAt(0) : ""}
          </span>
        </div>

        {/* Entity Menu */}
        <EntityMenu
          entities={app.entities_info?.entities || []}
          onSelect={handleEntitySelect}
          isOpen={isMenuOpen}
        />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
