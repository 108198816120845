import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import CustomTextField from "components/mui/text-field/text-field";
import Button from "components/mui/button/button";
import { EditMilestoneDialogProps, Milestone } from "./types/editing";
import { ProjectStatus } from "./utils/editing";

const EditMilestoneDialog: React.FC<EditMilestoneDialogProps> = ({
  open,
  onClose,
  milestone,
  onSave,
}) => {
  const [formData, setFormData] = useState<Milestone | null>(milestone);
  const theme = useTheme();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (formData) {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    if (formData) {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSave = async () => {
    if (formData) {
      await onSave(formData);
      onClose();
    }
  };

  if (!formData) return null;

  return (
    <Dialog
      open={open}
      PaperProps={{
        component: "form",
        sx: {
          width: "50%",
          minWidth: "400px",
        },
      }}
    >
      {" "}
      <DialogTitle>Edit Milestone</DialogTitle>
      <DialogContent>
        {/* Name Field */}
        <CustomTextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        {/* Status Field */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Status</InputLabel>
          <Select
            label="Status"
            name="status"
            value={formData.status.toString()}
            onChange={handleSelectChange}
          >
            {Object.entries(ProjectStatus)
              .filter(([key]) => isNaN(Number(key)))
              .map(([key, value]) => (
                <MenuItem key={value} value={value.toString()}>
                  {key}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {/* Daily Limit Field */}
        <CustomTextField
          label="Daily Limit"
          name="daily_limit"
          type="number"
          value={formData.daily_limit}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        {/* Milestone Limit Field */}
        <CustomTextField
          label="Milestone Limit"
          name="Milestone_limit"
          type="number"
          value={formData.Milestone_limit}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        {/* Max Offline Tasks Field */}
        <CustomTextField
          label="Max Offline Tasks"
          name="max_offline_tasks"
          type="number"
          value={formData.max_offline_tasks}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        {/* Deadline Field */}
        <CustomTextField
          label="Deadline"
          name="deadline"
          type="date"
          value={formData.deadline}
          onChange={handleChange}
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
        />

        {/* Order Field */}
        <CustomTextField
          label="Order"
          name="order"
          type="number"
          value={formData.order}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 24px",
        }}
      >
        {" "}
        <Button
          sx={{
            color: theme.custom.neutralColors.color11,
            backgroundColor: theme.palette.primary.main,
            width: "45%",
          }}
          onClick={handleSave}
          label="Save"
        />
        <Button
          sx={{
            color: theme.palette.primary.main,
            backgroundColor: theme.custom.neutralColors.color11,
            border: ` 1px solid ${theme.palette.primary.main}`,
            width: "45%",
          }}
          onClick={onClose}
          label="Cancel"
        />
      </DialogActions>
    </Dialog>
  );
};

export default EditMilestoneDialog;
