import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormHelperText from "@mui/material/FormHelperText";
import { CustomPasswordFieldProps } from "./types/PassField";

const CustomPasswordField: React.FC<CustomPasswordFieldProps> = ({
  width = "100%",
  helperText,
  error = false,
  iconSrc,
  ...outlinedInputProps
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  return (
    <FormControl
      sx={{
        width: width,
        marginBottom: "10px",
      }}
      variant="outlined"
      error={error}
    >
      <OutlinedInput
        id="outlined-adornment-password"
        type={showPassword ? "text" : "password"}
        startAdornment={
          iconSrc && (
            <InputAdornment position="start">
              <img src={iconSrc} style={{ width: "24px", height: "24px" }} />
            </InputAdornment>
          )
        }
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label={showPassword ? "Hide password" : "Show password"}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        sx={{
          "& .MuiOutlinedInput-input": {
            padding: "14px",
          },
          "& .MuiOutlinedInput-input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px transparent inset",
            WebkitTextFillColor: "inherit",
            caretColor: "inherit",
          },
        }}
        {...outlinedInputProps}
      />
      {helperText && (
        <FormHelperText error={error} sx={{ marginLeft: "14px" }}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default CustomPasswordField;
