import React, { useEffect, useState } from "react";
import axios from "axios";
import { Typography, useTheme, Box, Avatar, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button } from "components/mui/button";
import CustomPasswordField from "components/mui/pass-text-field/pass-text-field";
import CustomTextField from "components/mui/text-field/text-field";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as AddPhotoAlternateIcon } from "./assets/imgs/Frame 427319279.svg";
import person from "./assets/imgs/fluent_person-28-regular.svg";
import phone from "./assets/imgs/iconamoon_phone-thin.svg";
import email from "./assets/imgs/mage_email-notification.svg";
import pass from "./assets/imgs/solar_lock-password-linear.svg";
import Styles from "./assets/scss/setting.module.scss";
import { uiActions } from "store/ui/ui-slice";
import { appActions } from "store/slices/app";

const Profile: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL || "";
  const mediaURL = process.env.REACT_APP_MEDIA_URL;
  const app = useSelector((state: any) => state.app?.allData || {});

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [profileImage, setProfileImage] = useState<string | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: "" }));
  };

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const response = await axios.get(
          `${apiURL}/api/members/${app.memberId}/`
        );
        if (response.data.avatar) {
          const fullImageUrl = `${mediaURL}/profile_images/${response.data.avatar}`;
          setProfileImage(fullImageUrl);
        }
      } catch (error) {
        console.error("Error fetching profile image:", error);
      }
    };

    if (app.avatar) {
      const fullAvatarUrl = app.avatar.startsWith("http")
        ? app.avatar
        : `${mediaURL}/profile_images/${app.avatar}`;
      setProfileImage(fullAvatarUrl);
    } else {
      fetchProfileImage();
    }

    const storedFirstName = localStorage.getItem("firstName");
    const storedLastName = localStorage.getItem("lastName");
    const storedProfileImage = localStorage.getItem("profileImage");

    if (storedFirstName)
      setFormData((prev) => ({ ...prev, firstName: storedFirstName }));
    if (storedLastName)
      setFormData((prev) => ({ ...prev, lastName: storedLastName }));
    if (storedProfileImage) setProfileImage(storedProfileImage);
  }, [apiURL, app.memberId, app.avatar]);

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("profileImage", file);

      try {
        const response = await axios.post(
          `${apiURL}/upload/${app.memberId}/`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (response.data.imageName) {
          const fullImageUrl = `${mediaURL}/profile_images/${response.data.imageName}`;
          setProfileImage(fullImageUrl);
          localStorage.setItem("profileImage", fullImageUrl);
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const handleDeleteImage = async () => {
    await axios.delete(`${apiURL}/delete-avatar/${app.memberId}/`);
    setProfileImage(null);
    localStorage.removeItem("profileImage");
  };

  const handleUpdate = async () => {
    setIsSubmitting(true);

    try {
      const response = await axios.put(
        `${apiURL}/api/members/${app.memberId}/`,
        {
          firstname: formData.firstName,
          lastname: formData.lastName,
          email: formData.email,
          phone: formData.phone,
          password: formData.password,
          avatar: profileImage,
        }
      );

      if (response.data.isOk) {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: "success",
          })
        );

        localStorage.setItem("firstName", formData.firstName);
        localStorage.setItem("lastName", formData.lastName);

        dispatch(
          appActions.setAppData({
            data: {
              ...app,
              first_name: formData.firstName,
              last_name: formData.lastName,
              email: formData.email,
              avatar: profileImage,
            },
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "error",
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error updating member:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormValid = Object.values(errors).every((error) => !error);

  return (
    <Box sx={{ marginBottom: theme.spacing(3) }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: theme.spacing(2),
          marginBottom: "30px",
        }}
      >
        <Box sx={{ position: "relative" }}>
          <Avatar
            sx={{ width: 100, height: 100 }}
            src={profileImage || undefined}
          >
            {!profileImage &&
              `${formData.firstName?.[0] || ""}${formData.lastName?.[0] || ""}`}
          </Avatar>
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="profile-image-upload"
            type="file"
            onChange={handleImageUpload}
          />
          <label htmlFor="profile-image-upload">
            <IconButton
              component="span"
              sx={{
                position: "absolute",
                bottom: 0,
                right: 0,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                "&:hover": { backgroundColor: theme.palette.primary.dark },
              }}
            >
              <AddPhotoAlternateIcon />
            </IconButton>
          </label>
        </Box>
        {profileImage && (
          <IconButton
            style={{ width: "10%" }}
            onClick={handleDeleteImage}
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: theme.spacing(1),
          }}
        >
          <Typography variant="body1" sx={{ fontWeight: "bold" }}>
            {`${app.first_name} ${app.last_name}`}
          </Typography>
          <Typography
            variant="body2"
            sx={{ color: theme.palette.text.secondary }}
          >
            {app.email}
          </Typography>
        </Box>
      </Box>

      <Box className={Styles.fieldsContainer}>
        <Box className={Styles.leftColumn}>
          <Box sx={{ marginBottom: theme.spacing(3) }}>
            <Typography
              sx={{
                color: theme.custom.font.dark,
                marginBottom: theme.spacing(1),
              }}
              className={Styles.Typography}
            >
              First Name
            </Typography>
            <CustomTextField
              placeholder="Enter your first name"
              width="100%"
              value={formData.firstName}
              onChange={handleChange}
              name="firstName"
              error={!!errors.firstName}
              helperText={errors.firstName}
              variant="outlined"
              iconSrc={person}
            />
          </Box>
          <Box sx={{ marginBottom: theme.spacing(3) }}>
            <Typography
              sx={{
                color: theme.custom.font.dark,
                marginBottom: theme.spacing(1),
              }}
              className={Styles.Typography}
            >
              Last Name
            </Typography>
            <CustomTextField
              placeholder="Enter your last name"
              width="100%"
              value={formData.lastName}
              onChange={handleChange}
              name="lastName"
              error={!!errors.lastName}
              helperText={errors.lastName}
              variant="outlined"
              iconSrc={person}
            />
          </Box>
        </Box>

        <Box className={Styles.rightColumn}>
          <Box sx={{ marginBottom: theme.spacing(3) }}>
            <Typography
              sx={{
                color: theme.custom.font.dark,
                marginBottom: theme.spacing(1),
              }}
              className={Styles.Typography}
            >
              E-mail
            </Typography>
            <CustomTextField
              placeholder="default@example.com"
              width="100%"
              value={formData.email}
              onChange={handleChange}
              name="email"
              error={!!errors.email}
              helperText={errors.email}
              variant="outlined"
              iconSrc={email}
            />
          </Box>

          <Box sx={{ marginBottom: theme.spacing(3) }}>
            <Typography
              className={Styles.Typography}
              sx={{
                color: theme.custom.font.dark,
                marginBottom: theme.spacing(1),
              }}
            >
              Password
            </Typography>
            <CustomPasswordField
              placeholder="At least 8 characters"
              value={formData.password}
              width="100%"
              onChange={handleChange}
              name="password"
              error={!!errors.password}
              helperText={errors.password}
              iconSrc={pass}
            />
          </Box>
        </Box>
      </Box>

      <Button
        variant="contained"
        color="primary"
        onClick={handleUpdate}
        disabled={!isFormValid || isSubmitting}
        sx={{ alignSelf: "flex-start", width: "20%" }}
        label="Update"
      />
    </Box>
  );
};

export default Profile;
