import React from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { IPropsWithStyle } from "./types/menu";

const CustomSelect: React.FC<IPropsWithStyle> = ({
  value,
  onChange,
  options,
  label,
  displayEmpty = false,
  disabled = false,
  className,
  fontFamily = "'Cairo', sans-serif",
  icon,
}) => {
  return (
    <FormControl
      fullWidth
      disabled={disabled}
      className={className}
      style={{ fontFamily }}
    >
      {label && <InputLabel style={{ fontFamily }}>{label}</InputLabel>}
      <Select
        style={{
          marginBottom: "10px",
          fontFamily,
        }}
        value={value}
        onChange={onChange}
        displayEmpty={displayEmpty}
        label={label}
        sx={{
          "& .MuiSelect-select": {
            fontFamily,
            textAlign: "left",
            direction: "ltr",
            display: "flex",
            alignItems: "center",
          },
        }}
        renderValue={(selected) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {icon && <span style={{ marginRight: "8px" }}>{icon}</span>}
            {selected || (label && displayEmpty ? label : "Select an option")}
          </div>
        )}
      >
        {displayEmpty && (
          <MenuItem value="" disabled style={{ fontFamily }}>
            {label || "Select an option"}
          </MenuItem>
        )}
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={{ fontFamily, textAlign: "left", direction: "ltr" }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
