import React from "react";
import Button from "components/mui/button/button";
import styles from "./assets/scss/empty-content.module.scss";
import plus from "./assets/imgs/plus.svg";
import { Typography, useTheme } from "@mui/material";
import { IProps } from "./types/empty-content";

const EmptyContent: React.FC<IProps> = ({ text, linkText, onChange }) => {
  const theme = useTheme();

  return (
    <div className={styles.projectsContainer}>
      <Button
        label={<img src={plus} alt="plus" />}
        sx={{
          color: theme.custom.font.dark,
          backgroundColor: theme.custom.neutralColors.color11,
          border: `1px solid ${theme.custom.neutralColors.color4} !important`,
          boxShadow: `0 4px 8px ${theme.custom.neutralColors.color3}`,
        }}
        className={styles.btn}
        onClick={onChange}
      />
      <Typography
        variant="body2"
        className={styles.cardText}
        style={{
          color: theme.custom.font.light,
          fontSize: "25px",
          fontWeight: "400",
          textAlign: "center",
          marginTop: "8px",
        }}
      >
        {text}{" "}
        {linkText && (
          <Button
            label={linkText}
            sx={{
              color: theme.palette.primary.light,
              backgroundColor: "transparent",

              textTransform: "none",
              fontSize: "25px",
              fontWeight: "400",
              padding: "0",
              width: "auto",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={onChange}
          />
        )}
      </Typography>
    </div>
  );
};

export default EmptyContent;
