import React, { useState } from "react";
import styles from "./assets/scss/createnewpass.module.scss";
import CustomCard from "components/mui/card/card";
import CustomPasswordField from "components/mui/pass-text-field/pass-text-field";
import { Button } from "components/mui/button";
import passicon from "./assets/imgs/Group.svg";
import note from "./assets/imgs/Vector.svg";
import { useNavigate } from "react-router-dom";
import PasswordStrengthIndicator from "./password-strength-indicator";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "store/ui/ui-slice";
import { useTheme } from "@mui/material";
import { Boarding } from "layouts/boarding";

const CreatNewPass = () => {
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const app = useSelector((state: any) => state.app?.allData || {});
  const theme = useTheme();

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };
  const apiURL = process.env.REACT_APP_API_URL || "";

  const handleLogin = async () => {
    setIsSubmitting(true);

    try {
      const response = await axios.post(`${apiURL}/new-password/`, {
        id: app.memberId,
        new_password: password,
      });
      if (response.data.isOk) {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "Password changed successfully",
            level: "success",
          })
        );
        navigate("../success");
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("An error occurred while changing the password", error);
      dispatch(
        uiActions.updateNotification({
          message: "An error occurred while changing the password",
          level: "error",
        })
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const calculatePasswordStrength = (password: string) => {
    const criteria = [
      password.length >= 8,
      /[A-Z]/.test(password),
      /[a-z]/.test(password),
      /[0-9]/.test(password),
      /[^A-Za-z0-9]/.test(password),
    ];

    const strength = criteria.filter(Boolean).length;
    return strength;
  };

  const passwordStrength = calculatePasswordStrength(password);

  const handleSignupClick = () => {
    navigate("../signup/");
  };

  return (
    <Boarding
      paragraphContent="Don’t Have an account?"
      cardLabel="Create new Password"
    >
      <div className={styles.inputContainer}>
        <div className={styles.signupElement}>
          <label
            style={{
              color: theme.custom.font.black,
              fontWeight: 400,
              fontSize: "18px",
            }}
          >
            Password
          </label>
          <CustomPasswordField
            iconSrc={passicon}
            placeholder="At least 8 characters"
            value={password}
            width="100%"
            onChange={handlePasswordChange}
            // error={password.length < 8}
          />
        </div>

        <PasswordStrengthIndicator strength={passwordStrength} />
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          marginBottom: "20px",
        }}
      >
        <img
          src={note}
          alt="Note Icon"
          style={{ width: "16px", height: "16px" }}
        />
        <p
          style={{
            fontSize: "14px",
            margin: 0,
            color: theme.palette.primary.main,
          }}
        >
          Password must contain uppercase, lowercase, numbers and symbols.
        </p>
      </div>

      <Button
        label="Confirm"
        sx={{
          color: theme.custom.neutralColors.color11,
          backgroundColor: theme.palette.primary.light,
          width: "100%",
        }}
        onClick={handleLogin}
        disabled={isSubmitting}
      />

      <hr
        style={{
          border: "1px solid #847E8E",
          width: "100%",
          margin: "20px 0",
        }}
      ></hr>
      <p style={{ fontSize: "14px" }}>
        By proceeding, you agree to the{" "}
        <span style={{ color: theme.palette.primary.light }}>
          Terms of Service
        </span>{" "}
        and
        <span style={{ color: theme.palette.primary.light }}>
          Privacy Policy
        </span>
      </p>
    </Boarding>
  );
};

export default CreatNewPass;
