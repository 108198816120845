import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  useTheme,
} from "@mui/material";
import CustomTextField from "components/mui/text-field/text-field";
import Button from "components/mui/button/button";
import { useLocation } from "react-router-dom";
import { uiActions } from "store/ui/ui-slice";
import { useDispatch } from "react-redux";
import { AddMilestone, ProjectStatus } from "./types/projects-details";

const CreateMilestone: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formData, setFormData] = useState<AddMilestone>({
    name: "",
    max_offline_tasks: "0",
    daily_limit: 0,
    Milestone_limit: 0,
    deadline: "",
    status: 1,
    order: 0,
  });
  const theme = useTheme();
  const apiURL = process.env.REACT_APP_API_URL || "";
  const location = useLocation();
  const dispatch = useDispatch();

  const projectId = location.state?.projectId;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (e: SelectChangeEvent<string>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: Number(value) });
  };

  const handleCreate = async () => {
    try {
      const response = await fetch(`${apiURL}/api/milestones/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...formData,
          project: projectId,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || "Failed to create milestone");
      }

      if (responseData.isOk) {
        dispatch(
          uiActions.updateNotification({
            message: responseData.message,
            level: "success",
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: responseData.message || "Error creating milestone",
            level: "error",
          })
        );
      }

      console.log("Milestone created:", responseData.data);
      setDialogOpen(false);
    } catch (error: any) {
      console.error("Error creating milestone:", error);
      dispatch(
        uiActions.updateNotification({
          message: error.message || "An error occurred",
          level: "error",
        })
      );
    }
  };

  return (
    <div>
      <Button
        onClick={() => setDialogOpen(true)}
        label="Create Milestone"
        sx={{
          color: theme.custom.neutralColors.color11,
          backgroundColor: theme.palette.primary.main,
          flex: 4,
          borderRadius: "10px",
          width: "100%",
          marginTop: "30px",
        }}
      />

      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        PaperProps={{
          component: "form",
          sx: {
            width: "50%",
            minWidth: "400px",
          },
        }}
      >
        <DialogTitle>Create Milestone</DialogTitle>
        <DialogContent>
          {/* Name Field */}
          <CustomTextField
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          {/* Status Field */}
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              name="status"
              value={formData.status.toString()}
              onChange={handleSelectChange}
            >
              {Object.entries(ProjectStatus)
                .filter(([key]) => isNaN(Number(key)))
                .map(([key, value]) => (
                  <MenuItem key={value} value={value.toString()}>
                    {key}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {/* Daily Limit Field */}
          <CustomTextField
            label="Daily Limit"
            name="daily_limit"
            type="number"
            value={formData.daily_limit}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          {/* Milestone Limit Field */}
          <CustomTextField
            label="Milestone Limit"
            name="Milestone_limit"
            type="number"
            value={formData.Milestone_limit}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          {/* Max Offline Tasks Field */}
          <CustomTextField
            label="Max Offline Tasks"
            name="max_offline_tasks"
            type="number"
            value={formData.max_offline_tasks}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />

          {/* Deadline Field */}
          <CustomTextField
            label="Deadline"
            name="deadline"
            type="date"
            value={formData.deadline}
            onChange={handleChange}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />

          {/* Order Field */}
          <CustomTextField
            label="Order"
            name="order"
            type="number"
            value={formData.order}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "16px 24px",
          }}
        >
          <Button
            sx={{
              color: theme.custom.neutralColors.color11,
              backgroundColor: theme.palette.primary.main,
              width: "45%",
            }}
            onClick={handleCreate}
            label="Create"
          />
          <Button
            sx={{
              color: theme.palette.primary.main,
              backgroundColor: theme.custom.neutralColors.color11,
              border: ` 1px solid ${theme.palette.primary.main}`,
              width: "45%",
            }}
            onClick={() => setDialogOpen(false)}
            label="Cancel"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateMilestone;
