import { useEffect, useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useTheme,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  IconButton,
} from "@mui/material";
import { Main } from "layouts/main";
import projects from "./assets/imgs/tracker.svg";
import styles from "./assets/scss/time-tracking.module.scss";
import CustomTextField from "components/mui/text-field/text-field";
import { Button } from "components/mui/button";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "store/ui/ui-slice";
import auto from "./assets/imgs/Group.svg";
import manual from "./assets/imgs/OBJECTS.svg";
import CustomSelect from "components/mui/menu/menu";
import { Client } from "./types/time-tracking";
import task from "./assets/imgs/hugeicons_task-01.svg";
import close from "./assets/imgs/Vector.svg";
import time from "./assets/imgs/timer.svg";

const TimeTracking = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const apiURL = process.env.REACT_APP_API_URL || "";
  const app = useSelector((state: any) => state.app?.allData || {});

  const [openDialog, setOpenDialog] = useState(false);
  const [isManualTracker, setIsManualTracker] = useState(false);
  const [taskName, setTaskName] = useState("");
  const [selectedProject, setSelectedProject] = useState("");
  const [selectedMilestone, setSelectedMilestone] = useState("");
  const [notes, setNotes] = useState("");
  const [projectsList, setProjectsList] = useState<Client[]>([]);
  const [milestonesList, setMilestonesList] = useState<Client[]>([]);
  const [manualStartTime, setManualStartTime] = useState("");
  const [manualEndTime, setManualEndTime] = useState("");
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [startTime, setStartTime] = useState<string | null>(null);
  const [enabledTrackingTypes, setEnabledTrackingTypes] = useState<number[]>(
    []
  );
  const [taskId, setTaskId] = useState<string | null>(null);
  const formattedStartTime = manualStartTime
    ? new Date(manualStartTime).toISOString()
    : null;
  const formattedEndTime = manualEndTime
    ? new Date(manualEndTime).toISOString()
    : null;

  useEffect(() => {
    const persistedTimerState = JSON.parse(
      localStorage.getItem("timerState") || "{}"
    );
    if (persistedTimerState.isTimerRunning) {
      setIsTimerRunning(true);
      setStartTime(persistedTimerState.startTime);
      setElapsedTime(
        Math.floor(
          (new Date().getTime() -
            new Date(persistedTimerState.startTime).getTime()) /
            1000
        )
      );
    }
    const persistedTaskId = localStorage.getItem("taskId");
    if (persistedTaskId) {
      setTaskId(persistedTaskId);
    }
  }, []);

  useEffect(() => {
    if (isTimerRunning) {
      localStorage.setItem(
        "timerState",
        JSON.stringify({
          isTimerRunning,
          startTime,
          elapsedTime,
        })
      );
    } else {
      localStorage.removeItem("timerState");
    }
    if (taskId) {
      localStorage.setItem("taskId", taskId);
    } else {
      localStorage.removeItem("taskId");
    }
  }, [isTimerRunning, startTime, elapsedTime, taskId]);

  useEffect(() => {
    fetchEnabledTrackingTypes();
    fetchProjects();
  }, [apiURL, app.entityId]);

  const fetchEnabledTrackingTypes = async () => {
    try {
      const response = await axios.get(
        `${apiURL}/get-enabled-tracking-types/?entity_id=${app.entityId}`
      );
      const enabledTypes = response.data.enabled_tracking_types.map(
        (type: any) => type.tracking_type
      );
      setEnabledTrackingTypes(enabledTypes);
    } catch (error) {
      console.error("Error fetching enabled tracking types:", error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${apiURL}/list-projects/`, {
        params: { entity_id: app.entityId },
      });
      setProjectsList(response.data.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const fetchMilestones = async (projectId: any) => {
    try {
      const memberId = app.memberId;
      const response = await axios.get(
        `${apiURL}/projects-milestones/${projectId}/`
      );
      if (Array.isArray(response.data.data)) {
        setMilestonesList(response.data.data);
      } else {
        console.error("API response is not an array:", response.data);
        setMilestonesList([]);
      }
    } catch (error) {
      console.error("Error fetching milestones:", error);
      setMilestonesList([]);
    }
  };

  useEffect(() => {
    if (selectedProject) {
      fetchMilestones(selectedProject);
    }
  }, [selectedProject]);

  const isFormValid = taskName && selectedProject && selectedMilestone && notes;

  const handleStart = async () => {
    const startTime = new Date().toISOString();
    setStartTime(startTime);
    setIsTimerRunning(true);
    setElapsedTime(0);

    const taskData = {
      start_time: startTime,
      task_name: taskName,
      note: notes,
      status: 2,
      tracking_type: 2,
      member: app.memberId,
      milestone: selectedMilestone,
      entity: app.entityId,
    };

    try {
      const response = await axios.post(`${apiURL}/api/tasks/`, taskData);
      setTaskId(response.data.data.id);
      console.log("Task created successfully:", response.data);
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  const handleEnd = async () => {
    const endTime = new Date().toISOString();

    const taskData = {
      end_time: endTime,
      status: 3,
    };

    try {
      if (taskId) {
        const response = await axios.put(
          `${apiURL}/api/tasks/${taskId}/`,
          taskData
        );
        if (response.data.isOk) {
          dispatch(
            uiActions.updateNotification({
              message: response.data.message,
              level: "success",
            })
          );
        } else {
          dispatch(
            uiActions.updateNotification({
              message: response.data.message || "error",
              level: "error",
            })
          );
        }
        console.log("Task updated successfully:", response.data);
        handleCloseDialog();
      } else {
        console.error("Task ID is not available");
      }
    } catch (error) {
      console.error("Error updating task:", error);
    } finally {
      setIsTimerRunning(false);
      setStartTime(null);
      setTaskId(null); // Reset the task ID
    }
  };

  const handleManualSubmit = async () => {
    const taskData = {
      start_time: formattedStartTime,
      end_time: formattedEndTime,
      task_name: taskName,
      note: notes,
      status: 3,
      tracking_type: 1,
      member: app.memberId,
      milestone: selectedMilestone,
      entity: app.entityId,
    };

    try {
      const response = await axios.post(`${apiURL}/api/tasks/`, taskData);
      console.log("Task created successfully:", response.data);
      handleCloseDialog();
      if (response.data.isOk) {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: "success",
          })
        );
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "error",
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error creating task:", error);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isTimerRunning) {
      interval = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerRunning]);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(secs).padStart(2, "0")}`;
  };

  const gridData = [
    {
      image: auto,
      title: "Live tracker",
      description:
        "Activate the tool to start the tracker, and it will begin counting automatically. You can stop it at any time.",
      buttonText: "Getting started",
      onClick: () => {
        setIsManualTracker(false);
        setOpenDialog(true);
      },
      enabled: enabledTrackingTypes.includes(2),
    },
    {
      image: manual,
      title: "Manual tracker",
      description:
        "Manually enter the start and end times for the task you worked on, then submit the record to track your time.",
      buttonText: "Getting started",
      onClick: () => {
        setIsManualTracker(true);
        setOpenDialog(true);
      },
      enabled: enabledTrackingTypes.includes(1),
    },
  ];

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setTaskName("");
    setSelectedProject("");
    setSelectedMilestone("");
    setNotes("");
    setManualStartTime("");
    setManualEndTime("");
  };

  return (
    <Main header={{ icon: projects, title: "Time Tracking" }}>
      <p
        style={{
          fontWeight: 600,
          fontSize: "24px",
          textAlign: "left",
          color: theme.custom.font.dark,
        }}
      >
        We offer two powerful tools for time tracking: Manual and Live Time
        Tracker. Start tracking your time today!
      </p>

      <Grid container spacing={4}>
        {gridData.map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Card
              className={styles.card}
              sx={{
                textAlign: "left",
                margin: "2px",
                boxShadow: 3,
                "&:hover": {
                  boxShadow: 6,
                },
              }}
            >
              {/* Image Section */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                  height: "100%",
                  padding: "3px",
                }}
              >
                <CardMedia
                  component="img"
                  image={item.image}
                  alt={item.title}
                  sx={{
                    width: "250px",
                    height: "200px",
                  }}
                />
              </Box>

              {/* Content Section */}
              <Box sx={{ flexGrow: 1, padding: 2 }}>
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={{
                      fontWeight: 600,
                      fontSize: "24px",
                      color: theme.palette.primary.main,
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 600,
                      fontSize: "16px",
                      textAlign: "left",
                      color: theme.custom.neutralColors.color8,
                    }}
                  >
                    {item.description}
                  </Typography>
                </CardContent>
                <CardContent>
                  <Button
                    onClick={item.onClick}
                    label={item.buttonText}
                    sx={{
                      color: theme.custom.neutralColors.color11,
                      backgroundColor: theme.palette.secondary.dark,
                      flex: 4,
                      borderRadius: "20px",
                      width: "45%",
                    }}
                    disabled={!item.enabled}
                  />
                </CardContent>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/* Dialog Component */}
      <Dialog
        open={openDialog}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            if (isManualTracker) {
              handleManualSubmit();
            } else {
              handleStart();
            }
          },
          sx: {
            width: "50%",
            minWidth: "400px",
            overflowX: "hidden",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "32px",
            fontWeight: 600,
            color: theme.palette.primary.main,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px 24px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 600,
              fontSize: "32px",
              color: theme.palette.primary.main,
            }}
          >
            {isManualTracker
              ? "Manual Tracking"
              : isTimerRunning
              ? "Timer Running"
              : "Create Tracking"}
          </Typography>

          <IconButton
            onClick={handleCloseDialog}
            sx={{
              padding: 0,
              width: "5%",

              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            <img
              src={close}
              alt="Close"
              style={{ width: "20px", height: "20px" }}
            />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* Hide all fields when timer is running */}
          {!isTimerRunning ? (
            <>
              {/* Common Fields */}
              <div className={styles.ClientElement}>
                <label
                  style={{
                    color: theme.custom.font.black,
                    fontWeight: 400,
                    fontSize: "18px",
                  }}
                >
                  Task Name{" "}
                  <span style={{ color: theme.palette.error.main }}>*</span>{" "}
                </label>
                <CustomTextField
                  placeholder="Task Name"
                  width="100%"
                  iconSrc={task}
                  value={taskName}
                  onChange={(e) => setTaskName(e.target.value)}
                  required
                />
              </div>

              <div className={styles.ClientElement}>
                <label
                  style={{
                    color: theme.custom.font.black,
                    fontWeight: 400,
                    fontSize: "18px",
                  }}
                >
                  Select Project
                  <span style={{ color: theme.palette.error.main }}>
                    *
                  </span>{" "}
                </label>
                <CustomSelect
                  value={
                    projectsList.find(
                      (project) => project.id === selectedProject
                    )?.name || ""
                  }
                  onChange={(e: any) => setSelectedProject(e.target.value)}
                  options={projectsList.map((project) => ({
                    value: project.id,
                    label: project.name,
                  }))}
                  displayEmpty
                  className={styles.menu}
                  open={false}
                />
              </div>

              <div className={styles.ClientElement}>
                <label
                  style={{
                    color: theme.custom.font.black,
                    fontWeight: 400,
                    fontSize: "18px",
                  }}
                >
                  Select Milestone
                  <span style={{ color: theme.palette.error.main }}>
                    *
                  </span>{" "}
                </label>
                <CustomSelect
                  value={
                    milestonesList.find(
                      (milestone) => milestone.id === selectedMilestone
                    )?.name || ""
                  }
                  onChange={(e: any) => setSelectedMilestone(e.target.value)}
                  options={
                    Array.isArray(milestonesList)
                      ? milestonesList.map((milestone) => ({
                          value: milestone.id,
                          label: milestone.name,
                        }))
                      : []
                  }
                  displayEmpty
                  className={styles.menu}
                  open={false}
                />
              </div>
              {isManualTracker && (
                <>
                  <div className={styles.ClientElement}>
                    <label
                      style={{
                        color: theme.custom.font.black,
                        fontWeight: 400,
                        fontSize: "18px",
                      }}
                    >
                      Start Time{" "}
                      <span style={{ color: theme.palette.error.main }}>*</span>{" "}
                    </label>
                    <CustomTextField
                      type="datetime-local"
                      value={manualStartTime}
                      onChange={(e) => setManualStartTime(e.target.value)}
                      required
                      iconSrc={time}
                    />
                  </div>

                  <div className={styles.ClientElement}>
                    <label
                      style={{
                        color: theme.custom.font.black,
                        fontWeight: 400,
                        fontSize: "18px",
                      }}
                    >
                      End Time{" "}
                      <span style={{ color: theme.palette.error.main }}>*</span>{" "}
                    </label>
                    <CustomTextField
                      type="datetime-local"
                      value={manualEndTime}
                      onChange={(e) => setManualEndTime(e.target.value)}
                      required
                      iconSrc={time}
                    />
                  </div>
                </>
              )}
              <div className={styles.ClientElement}>
                <label
                  style={{
                    color: theme.custom.font.black,
                    fontWeight: 400,
                    fontSize: "18px",
                  }}
                >
                  Notes{" "}
                  <span style={{ color: theme.palette.error.main }}>*</span>{" "}
                </label>
                <CustomTextField
                  margin="dense"
                  multiline
                  rows={4}
                  label="Notes"
                  fullWidth
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  required
                />
              </div>

              {/* Manual Tracker Fields */}
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: 2,
                height: "200px",
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 600,
                  color: theme.palette.primary.main,
                  border: `2px solid ${theme.palette.primary.main}`,
                  padding: "20px",
                  borderRadius: "5px",
                }}
              >
                {formatTime(elapsedTime)}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            padding: "16px 24px",
            justifyContent: "center",
          }}
        >
          {isManualTracker ? (
            <Button
              label="Submit"
              sx={{
                color: theme.custom.neutralColors.color11,
                backgroundColor: theme.palette.primary.main,
                width: "40%",
              }}
              type="submit"
              disabled={!isFormValid || !manualStartTime || !manualEndTime}
            />
          ) : !isTimerRunning ? (
            <Button
              label="Start"
              sx={{
                color: theme.custom.neutralColors.color11,
                backgroundColor: theme.palette.primary.main,
                width: "40%",
              }}
              type="submit"
              disabled={!isFormValid}
            />
          ) : (
            <Button
              label="End"
              sx={{
                color: theme.custom.neutralColors.color11,
                backgroundColor: theme.palette.primary.main,
                border: ` 1px solid ${theme.palette.primary.main}`,
                width: "40%",
              }}
              onClick={handleEnd}
            />
          )}
        </DialogActions>
      </Dialog>
    </Main>
  );
};

export default TimeTracking;
