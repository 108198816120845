import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import "./App.css";
import Login from "./pages/login/login";
import SignUp from "./pages/signup/signup";
import VerifyCodeInput from "./pages/verify/verify";
import ForgetPass from "./pages/forget-pass/forget-pass";
import Success from "./pages/success/success";
import CreatNewPass from "./pages/create-new-pass/create-new-pass";
import MySnackbar from "./components/mui/snackbar/snakbar";
import store from "store/index";
import { Home } from "pages/home";
import ProtectedRoute from "./components/protected-route/protected-route";
import { Projects } from "pages/projects";
import { Clients } from "pages/clients";
import { TimeTracking } from "pages/time-tracking";
import { Tasks } from "pages/tasks";
import { ProjectsDetails } from "pages/projects-details";
import { Report } from "pages/report";
import { MilestonesDetails } from "pages/milestons-details";
import { Setting } from "pages/setting";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <MySnackbar />
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/verify" element={<VerifyCodeInput />} />
            <Route path="/forgetpassword" element={<ForgetPass />} />
            <Route path="/success" element={<Success />} />
            <Route path="/changepassword" element={<CreatNewPass />} />
            <Route path="/Projects" element={<Projects />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/time-tracking" element={<TimeTracking />} />
            <Route path="/tasks" element={<Tasks />} />
            <Route path="/project-details" element={<ProjectsDetails />} />
            <Route path="/report" element={<Report />} />
            <Route path="/milestons-details" element={<MilestonesDetails />} />
            <Route path="/setting" element={<Setting />} />

            <Route
              path="/home"
              element={<Home />} //<ProtectedRoute element={<Home />} />}
            />
          </Routes>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
