import React from "react";
import {
  Drawer,
  List,
  ListItem,
  Button,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactComponent as HomeIcon } from "./assets/imgs/home.svg";
import { ReactComponent as Time } from "./assets/imgs/time.svg";
import { ReactComponent as Task } from "./assets/imgs/clipboard_17288168 1.svg";
import { ReactComponent as Report } from "./assets/imgs/report.svg";
import { ReactComponent as Setting } from "./assets/imgs/setting.svg";
import { ReactComponent as Projects } from "./assets/imgs/Vector.svg";
import { ReactComponent as Logout } from "./assets/imgs/signout.svg";
import { ReactComponent as Plus } from "./assets/imgs/ic_round-plus.svg";
import { ReactComponent as Crown } from "./assets/imgs/crown_6941697 1.svg";
import { ReactComponent as Client } from "./assets/imgs/Clip path group.svg";
import { ReactComponent as Invoice } from "./assets/imgs/hugeicons_invoice-02.svg";

import styles from "./assets/scss/side-panel.module.scss";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { IProps } from "./types/side-panel";

const SidePanel: React.FC = () => {
  const theme = useTheme();
  const app = useSelector((state: any) => state.app?.allData || {});
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems: IProps[] = [
    { label: `${app.entity_name}` },
    { label: "Home", iconBefore: <HomeIcon />, path: "/home/" },
    { label: "Time Tracking", iconBefore: <Time />, path: "/time-tracking/" },
    {
      label: "Clients",
      iconBefore: <Client />,
      iconAfter: <Crown />,
      path: "/clients/",
    },
    {
      label: "Projects",
      iconBefore: <Projects />,
      iconAfter: <Plus />,
      path: "/projects/",
    },
    { label: "Tasks", iconBefore: <Task />, path: "/tasks/" },
    { label: "Report", iconBefore: <Report />, path: "/report/" },
    {
      label: "Invoices",
      iconBefore: <Invoice />,
      iconAfter: <Crown />,
      path: "/create-invoice/",
    },
    { label: "Setting", iconBefore: <Setting />, path: "/setting/" },
    { label: "Logout", iconBefore: <Logout />, path: "/logout/" },
  ];

  const handleClick = (path: string) => {
    if (path === "/logout/") {
      localStorage.clear();
      navigate("/");
    } else {
      navigate(path);
    }
  };

  return (
    <div
      className={styles.drawer}
      style={{
        borderRight: `1px ${theme.custom.neutralColors.color2} solid`,
      }}
    >
      <List>
        {menuItems.map((item, index) => (
          <ListItem
            key={index}
            component="li"
            sx={{
              marginTop: item.path ? "3px" : "25px",
              marginBottom: item.path ? 0 : "20px",
              ":hover": {
                backgroundColor: item.path
                  ? theme.custom.neutralColors.color2
                  : "transparent",
              },
              backgroundColor:
                location.pathname === item.path
                  ? theme.custom.neutralColors.color13
                  : "transparent",
            }}
            className={styles.menuItems}
          >
            {item.path ? (
              <Button
                onClick={() => handleClick(item.path || "#")}
                fullWidth
                sx={{
                  textAlign: "left",
                  justifyContent: "flex-start",
                }}
              >
                {item.iconBefore && (
                  <span style={{ marginRight: "8px" }}>{item.iconBefore}</span>
                )}
                <Typography
                  sx={{
                    fontSize: "12px",
                    fontFamily: "Cairo",
                    flex: 1,
                    textAlign: "left",
                    color: theme.custom.font.black,
                    ":hover": {
                      color: theme.custom.font.light,
                    },
                  }}
                >
                  {item.label}
                </Typography>
                {item.iconAfter && (
                  <span style={{ marginLeft: "8px" }}>{item.iconAfter}</span>
                )}
              </Button>
            ) : (
              <div className={styles.entityNameContainer}>
                <div
                  className={styles.iconContainer}
                  style={{
                    backgroundColor: theme.palette.secondary.main,
                  }}
                >
                  <Typography
                    className={styles.iconText}
                    sx={{
                      color: theme.custom.neutralColors.color11,
                    }}
                  >
                    {`${app.first_name[0]}${app.last_name[0]}`}
                  </Typography>
                </div>
                <Typography
                  className={styles.entityName}
                  style={{
                    color: theme.custom.font.black,
                  }}
                >
                  {item.label}
                </Typography>
              </div>
            )}
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default SidePanel;
