import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styles from "./assets/scss/setting.module.scss";
import { uiActions } from "store/ui/ui-slice";
import CustomSelect from "components/mui/menu/menu";
import { SelectChangeEvent } from "@mui/material/Select";
import axios from "axios";
import { Button } from "components/mui/button";
import { ReactComponent as Project } from "./assets/imgs/briefing_1705213 (2) 1.svg";
import { ReactComponent as Mileston } from "./assets/imgs/reset_17237842 1 (1).svg";
import { ReactComponent as Task } from "./assets/imgs/graphic_15128974 1.svg";
import { ReactComponent as Main } from "./assets/imgs/reset_17237842 1.svg";

const Reset: React.FC = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiURL = process.env.REACT_APP_API_URL || "";
  const app = useSelector((state: any) => state.app?.allData || {});

  const [selectedMenu, setSelectedMenu] = useState<string>("");
  const [selectedProjectId, setSelectedProjectId] = useState<string>("");
  const [selectedMilestoneId, setSelectedMilestoneId] = useState<string>("");
  const [selectedTaskId, setSelectedTaskId] = useState<string>("");
  const [projectsList, setProjectsList] = useState<any[]>([]);
  const [milestonesList, setMilestonesList] = useState<any[]>([]);
  const [tasksData, setTasksData] = useState<any[]>([]);

  // Fetch Projects
  const fetchProjects = async () => {
    try {
      const response = await axios.get(`${apiURL}/list-projects/`, {
        params: { entity_id: app.entityId },
      });
      setProjectsList(response.data.data);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const fetchMilestones = async (projectId: string) => {
    try {
      const response = await axios.get(
        `${apiURL}/projects-milestones/${projectId}/`
      );

      console.log("Milestones API Response:", response.data);

      setMilestonesList(
        Array.isArray(response.data.data) ? response.data.data : []
      );
    } catch (error) {
      console.error("Error fetching milestones:", error);
      setMilestonesList([]);
    }
  };

  // Fetch Tasks
  const fetchTasks = async (milestoneId: string) => {
    try {
      const response = await axios.get(
        `${apiURL}/tasks-by-milestone-id/${milestoneId}/?page=3`
      );
      if (response.data.isOk) {
        setTasksData(response.data.data.tasks);
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "error",
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  // Handle Truncate Projects
  const handleTruncateProjects = async () => {
    try {
      const payload = {
        project_ids: selectedProjectId ? [selectedProjectId] : "all",
      };
      const response = await axios.post(`${apiURL}/truncate-project/`, payload);
      if (response.data.isOk) {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: "success",
          })
        );
        fetchProjects();
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "Failed to truncate projects",
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error truncating projects:", error);
      dispatch(
        uiActions.updateNotification({
          message: "An error occurred while truncating projects",
          level: "error",
        })
      );
    }
  };

  // Handle Truncate Milestones
  const handleTruncateMilestones = async () => {
    try {
      const payload = {
        milestone_ids: selectedMilestoneId ? [selectedMilestoneId] : "all",
      };
      const response = await axios.post(
        `${apiURL}/truncate-milestone/`,
        payload
      );
      if (response.data.isOk) {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: "success",
          })
        );
        fetchMilestones(selectedProjectId);
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "Failed to truncate milestones",
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error truncating milestones:", error);
      dispatch(
        uiActions.updateNotification({
          message: "An error occurred while truncating milestones",
          level: "error",
        })
      );
    }
  };

  // Handle Truncate Tasks
  const handleTruncateTasks = async () => {
    try {
      const payload = {
        task_ids: selectedTaskId ? [selectedTaskId] : "all",
      };
      const response = await axios.post(`${apiURL}/truncate-tasks/`, payload);
      if (response.data.isOk) {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message,
            level: "success",
          })
        );
        fetchTasks(selectedMilestoneId);
      } else {
        dispatch(
          uiActions.updateNotification({
            message: response.data.message || "Failed to truncate tasks",
            level: "error",
          })
        );
      }
    } catch (error) {
      console.error("Error truncating tasks:", error);
      dispatch(
        uiActions.updateNotification({
          message: "An error occurred while truncating tasks",
          level: "error",
        })
      );
    }
  };

  useEffect(() => {
    if (["projects", "milestones", "tasks"].includes(selectedMenu)) {
      fetchProjects();
    }
  }, [selectedMenu]);

  // Fetch milestones when project is selected
  useEffect(() => {
    if (selectedProjectId && ["milestones", "tasks"].includes(selectedMenu)) {
      fetchMilestones(selectedProjectId);
    }
  }, [selectedProjectId]);

  // Fetch tasks when milestone is selected
  useEffect(() => {
    if (selectedMilestoneId && selectedMenu === "tasks") {
      fetchTasks(selectedMilestoneId);
    }
  }, [selectedMilestoneId]);

  // Handle menu change
  const handleMenuChange = (event: SelectChangeEvent<string | number>) => {
    setSelectedMenu(event.target.value as string);
    setSelectedProjectId("");
    setSelectedMilestoneId("");
    setSelectedTaskId("");
  };

  const handleProjectChange = (event: SelectChangeEvent<string | number>) => {
    setSelectedProjectId(event.target.value as string);
  };

  const handleMilestoneChange = (event: SelectChangeEvent<string | number>) => {
    setSelectedMilestoneId(event.target.value as string);
  };

  const handleTaskChange = (event: SelectChangeEvent<string | number>) => {
    setSelectedTaskId(event.target.value as string);
  };

  const getSelectedName = (list: any[], selectedId: string) => {
    if (!Array.isArray(list)) {
      console.error("getSelectedName error: list is not an array", list);
      return "";
    }
    const selectedItem = list.find((item) => item.id === selectedId);
    if (!selectedItem) {
      return "";
    }
    return selectedItem.name || selectedItem.task_name || "";
  };

  // Render Select Menus
  const renderMenu = (
    label: string,
    value: string,
    options: any[],
    onChange: (event: SelectChangeEvent<string | number>) => void,
    icon?: React.ReactNode
  ) => {
    return (
      <>
        <label
          style={{
            color: theme.custom.font.black,
            fontWeight: 400,
            fontSize: "18px",
          }}
        >
          {label}
        </label>
        <CustomSelect
          value={value}
          onChange={onChange}
          options={options}
          displayEmpty
          className={styles.menu}
          open={false}
          icon={icon}
        />
      </>
    );
  };

  const renderProjectMenu = () => {
    return renderMenu(
      "Choose Project",
      getSelectedName(projectsList, selectedProjectId),
      projectsList.map((project) => ({
        value: project.id,
        label: project.name,
      })),
      handleProjectChange,
      <Project />
    );
  };

  const renderMilestoneMenu = () => {
    if (!Array.isArray(milestonesList)) {
      console.error("milestonesList is not an array:", milestonesList);
      return null;
    }

    return renderMenu(
      "Choose Milestone",
      getSelectedName(milestonesList, selectedMilestoneId),
      milestonesList.map((milestone) => ({
        value: milestone.id,
        label: milestone.name,
      })),
      handleMilestoneChange,
      <Mileston />
    );
  };

  const renderTaskMenu = () => {
    return renderMenu(
      "Choose Task",
      getSelectedName(tasksData, selectedTaskId),
      tasksData.map((task) => ({
        value: task.id,
        label: task.task_name,
      })),
      handleTaskChange,
      <Task />
    );
  };

  // Handle Reset Button Click
  const handleReset = () => {
    if (selectedMenu === "projects") {
      handleTruncateProjects();
    } else if (selectedMenu === "milestones") {
      handleTruncateMilestones();
    } else if (selectedMenu === "tasks") {
      handleTruncateTasks();
    }
  };

  return (
    <div className={styles.signupElement}>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <div style={{ flex: "1 1 45%", margin: "5px" }}>
          {renderMenu(
            "Reset ( Type )",
            selectedMenu,
            [
              { value: "projects", label: "Projects" },
              { value: "milestones", label: "Milestones" },
              { value: "tasks", label: "Tasks" },
            ],
            handleMenuChange,
            <Main />
          )}

          {selectedMenu === "projects" && renderProjectMenu()}
          {selectedMenu === "tasks" && renderProjectMenu()}
        </div>

        <div style={{ flex: "1 1 45%", margin: "5px" }}>
          {selectedMenu === "milestones" && renderProjectMenu()}
          {selectedMenu === "milestones" && renderMilestoneMenu()}
          {selectedMenu === "tasks" && renderMilestoneMenu()}
          {selectedMenu === "tasks" && renderTaskMenu()}
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        sx={{ alignSelf: "flex-start", width: "20%", marginTop: "70px" }}
        label="Reset"
        onClick={handleReset}
      />
    </div>
  );
};

export default Reset;
